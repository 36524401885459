import {css} from "@emotion/react";

import {Text, TextVariant} from "@pg-design/text";

import {IButtonSizePrimary} from "../components/Button";
import {ButtonVariantUnionType} from "../utils/button_variants";
import {sizeToVariantMap} from "../utils/dictionaries";

export const withTextVariantStyles = (variant: ButtonVariantUnionType, size: IButtonSizePrimary | undefined) => (props: {children: React.ReactNode}) => {
    switch (variant) {
        case "filled_primary":
        case "none_secondary":
        case "highlight_primary": {
            return <Text variant={size ? sizeToVariantMap[size] : TextVariant.BUTTON_MEDIUM} {...props} />;
        }

        case "banner_primary":
            const bannerPrimaryButtonStyle = css`
                font-weight: 600;
            `;
            return <Text variant="body_copy_0" css={bannerPrimaryButtonStyle} {...props} />;

        case "map_tooltip": {
            const mapToolTipButtonStyle = css`
                font-size: 1.106rem;
                line-height: 1.2rem;
                font-weight: 700;
            `;
            return <span css={mapToolTipButtonStyle} {...props} />;
        }

        default: {
            return <Text variant={TextVariant.BUTTON_MEDIUM} {...props} />;
        }
    }
};
