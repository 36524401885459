import {css} from "@emotion/react";

import {ICoreThemeDS} from "@pg-design/themes";

export const getInputStyle = (theme: ICoreThemeDS, multiLine: boolean, disabled?: boolean) => css`
    color: ${disabled ? theme.colors.gray["700"] : theme.colors.secondary};
    opacity: 1;
    border: 0;
    background: transparent;
    width: 100%;
    padding: 1px 2px; /* normalize to chrome styles */

    &::placeholder {
        color: ${theme.colors.gray["700"]};
    }

    outline: 0;

    &:focus {
        outline: 0;
    }

    ${multiLine &&
    css`
        resize: none;
    `}
    //File type styles
  &[type="file"]::file-selector-button {
        right: 3.6rem;
        position: absolute;
        background-color: transparent;
        text-transform: uppercase;
        font-size: 1.4rem;
        font-weight: 600;
        font-family: ${theme.globals.text.family};
        border: none;

        &:before {
            content: "$$$$$$$$$$$";
            position: absolute;
        }
    }
`;
