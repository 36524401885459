import Bugsnag from "@bugsnag/js";
import BugsnagPluginExpress from "@bugsnag/plugin-express";

import {bugsnagReleaseStage, buildImageTag} from "../../app/read_rp_environment_variables";
import {enabledBugsnagReleaseStages} from "./bugsnag_stages";

const BUGSNAG_SERVER_KEY = "a52130f0772b6f9cc733ebcaa934c198";

export const initServerBugsnag = () => {
    Bugsnag.start({
        apiKey: BUGSNAG_SERVER_KEY,
        appVersion: buildImageTag,
        releaseStage: bugsnagReleaseStage,
        enabledReleaseStages: enabledBugsnagReleaseStages,
        plugins: [BugsnagPluginExpress]
    });
};

export const getBugsnagExpressMiddleware = () => Bugsnag.getPlugin("express");

const isErrorType = (error: unknown): error is Error => {
    return Object.prototype.hasOwnProperty.call(error, "name") && Object.prototype.hasOwnProperty.call(error, "message");
};
export const notifyBugsnagServer = (error: unknown | Error, errorInfo: string, url?: string) => {
    const errorNormalized: Error = isErrorType(error)
        ? error
        : {
              name: "Unknown error",
              message: JSON.stringify(error)
          };

    Bugsnag.notify(errorNormalized, (event) => {
        event.addMetadata("metadata", {metaData: {errorInfo, url}});
    });

    return errorNormalized;
};
