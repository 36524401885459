import {css} from "@emotion/react";

import {IModalProps} from "../../../types/IModalProps";

export const modalValignStyle = (align: IModalProps["vAlign"]) => {
    switch (align) {
        case "center":
            return css`
                align-items: center;
            `;
        case "top":
            return css`
                align-items: flex-start;
            `;

        case "bottom":
            return css`
                align-items: flex-end;
            `;

        default:
            return "";
    }
};
