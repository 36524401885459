import React, {ReactNode} from "react";
import {css} from "@emotion/react";
import styled from "@emotion/styled";

import {calculateRemSize, w100} from "@pg-design/helpers-css";

import {ModalCloseButton} from "../atoms/ModalCloseButton";

interface IProps {
    breakpoint?: string;
    children?: ReactNode;
    className?: string;
}

export const ModalLayoutEmptyResponsive = (props: IProps) => {
    return (
        <Wrapper breakpoint={props.breakpoint} className={props.className}>
            <ModalCloseButton css={modalCloseButtonStyle} />
            {props.children}
        </Wrapper>
    );
};

const Wrapper = styled.div<Pick<IProps, "breakpoint">>`
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    overflow-x: hidden;
    ${w100};

    @media (min-width: ${({breakpoint, theme}) => breakpoint || theme.breakpoints.sm}) {
        flex-direction: row;
    }
`;

const modalCloseButtonStyle = css`
    position: absolute;
    inset: ${calculateRemSize(1)} ${calculateRemSize(1)} auto auto;
    cursor: pointer;
`;
