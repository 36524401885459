import {IStandardMetaData} from "../reducers/meta_data_reducer";

export function setClientMetaData(metaData: IStandardMetaData) {
    try {
        // update title
        window.document.title = metaData.title;
        // update meta data
        safelyUpdateMetaTag("og:title", metaData.title, "property");
        safelyUpdateMetaTag("description", metaData.description);
        safelyUpdateMetaTag("og:description", metaData.description, "property");
        safelyUpdateLinkTag("canonical", metaData.canonical);
        safelyUpdateMetaTag("og:url", metaData.canonical, "property");
        metaData.prev ? safelyUpdateLinkTag("prev", metaData.prev) : safelyRemoveLinkTag("prev");
        metaData.next ? safelyUpdateLinkTag("next", metaData.next) : safelyRemoveLinkTag("next");
        metaData.robots ? safelyUpdateMetaTag("robots", metaData.robots) : safelyRemoveMetaTag("robots");
    } catch (e) {
        // serverSide render
    }
}

function safelyUpdateMetaTag(name: string, text: string, propertyName = "name") {
    // prepare tag
    const searchName = propertyName === "property" ? `'${name}'` : name;
    const oldTag = window.document.querySelector(`meta[${propertyName}=${searchName}]`);
    const newTag = window.document.createElement("meta");
    newTag.setAttribute(propertyName, name);
    newTag.content = text;
    // update/add tag
    if (oldTag && oldTag.parentNode) {
        oldTag.parentNode.replaceChild(newTag, oldTag);
    } else {
        const head = window.document.querySelector("head");
        head && head.appendChild(newTag);
    }
}

function safelyRemoveMetaTag(name: string) {
    const oldTag = window.document.querySelector(`meta[name=${name}]`);
    if (oldTag && oldTag.parentNode) {
        oldTag.parentNode.removeChild(oldTag);
    }
}

function safelyUpdateLinkTag(name: string, text: string) {
    // prepare tag
    const oldTag = window.document.querySelector(`link[rel=${name}]`);
    const newTag = window.document.createElement("link");
    newTag.rel = name;
    newTag.href = text;
    // update/add tag
    if (oldTag && oldTag.parentNode) {
        oldTag.parentNode.replaceChild(newTag, oldTag);
    } else {
        const head = window.document.querySelector("head");
        head && head.appendChild(newTag);
    }
}

function safelyRemoveLinkTag(name: string) {
    const oldTag = window.document.querySelector(`link[rel=${name}]`);
    if (oldTag && oldTag.parentNode) {
        oldTag.parentNode.removeChild(oldTag);
    }
}
