import type {ForwardedRef, Ref, RefCallback} from "react";
import {useEffect, useRef} from "react";

const isRefCallback = <TRef>(ref: Ref<TRef>): ref is RefCallback<TRef> => {
    return typeof ref === "function";
};

export const useCombinedRefs = <TRef>(...refs: (ForwardedRef<TRef> | undefined)[]) => {
    const targetRef = useRef<TRef>(null);

    useEffect(() => {
        refs.forEach((ref) => {
            if (!ref) {
                return;
            }

            if (isRefCallback(ref)) {
                ref(targetRef.current);
            } else {
                ref.current = targetRef.current;
            }
        });
    }, [refs]);

    return targetRef;
};
