import type {BaseQueryFn, FetchArgs, FetchBaseQueryError} from "@reduxjs/toolkit/query";
import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";
import {Span} from "elastic-apm-node";
import qs from "query-string";

import {getApmTransactionName, getCookie, matchApiUrlByApiPrefix} from "@pg-mono/request";

import {IRPServicesMeta} from "../../store/types/IRPServicesMeta";
import {serverApiUrls} from "../constants/server_api_urls";
import {isServer} from "../read_rp_environment_variables";
import {appendCacheMissHeader} from "../utils/append_cache_miss_header";

const rpServicesBaseQuery: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (args, api, extraOptions) => {
    const endpointUrl = typeof args === "string" ? args : args.url;
    const serverApiUrl = matchApiUrlByApiPrefix(serverApiUrls, endpointUrl);

    const baseUrl = isServer ? serverApiUrl : "";
    const absoluteUrl = `${baseUrl}${endpointUrl}`;

    const adjustedArgs = typeof args === "string" ? `${absoluteUrl}` : {...args, url: `${absoluteUrl}`};

    const fetchWithApm: typeof fetch = async (input, init) => {
        let span: Span | null = null;
        const ssrUtils = (api.extra as {ssrUtils: IRPServicesMeta | null}).ssrUtils;
        const apm = ssrUtils?.apm;
        const requestUrl = typeof input === "string" ? input : (input as Request).url;

        if (isServer && apm) {
            const transactionName = getApmTransactionName(requestUrl);
            span = apm.transaction.startSpan(transactionName, "http", "node-fetch", {childOf: apm.transaction.traceparent});

            span?.addLabels({url: requestUrl}, true);
        }

        try {
            if (!init) {
                init = {};
            }

            if (!init.headers) {
                init.headers = new Headers();
            }

            if (isServer && span && apm) {
                (init.headers as Headers).set("traceparent", span.traceparent);
                (init.headers as Headers).set("tracestate", apm.tracestate || "");
            }

            ssrUtils?.serverProfile?.start(requestUrl);

            const result = await fetch(input, init);
            span?.setOutcome("success");
            return result;
        } catch (error) {
            if (span) {
                span?.setOutcome("failure");
            }
            throw error;
        } finally {
            if (span) {
                span.end();
                ssrUtils?.serverProfile?.stop(requestUrl);
            }
        }
    };

    const rpBaseQuery = fetchBaseQuery({
        paramsSerializer: qs.stringify,
        prepareHeaders: (headers, api) => {
            const customCsrfToken = headers.get("X-CSRFToken");
            const csrf = !isServer ? getCookie("csrftoken") : null;

            if (api.type === "mutation" && !customCsrfToken && csrf) {
                headers.set("X-CSRFToken", csrf);
            }

            appendCacheMissHeader(headers, api);

            return headers;
        },
        fetchFn: fetchWithApm,
        timeout: 30000
    });

    return rpBaseQuery(adjustedArgs, api, extraOptions);
};

export const rpApi = createApi({
    reducerPath: "rpApi",
    baseQuery: rpServicesBaseQuery,
    endpoints: () => ({}),
    tagTypes: ["ModularHouseOffer"]
});
