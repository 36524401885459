import React from "react";
import {css, Theme} from "@emotion/react";

import {PlaceholderIcon} from "@pg-design/icons";

export const Placeholder = () => (
    <div css={placeholderBase}>
        <PlaceholderIcon css={placeholderIcon} />
    </div>
);

const placeholderBase = (theme: Theme) => css`
    background-color: ${theme.colors.gray[300]};
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
`;

const placeholderIcon = (theme: Theme) => css`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(6);
    fill: ${theme.colors.gray[700]};
    max-width: 100%;
`;
