import {useSelector} from "react-redux";

import {SearchIcon} from "@pg-design/icons-module";
import {rpAppLink} from "@pg-mono/rp-routes";

import {IRPStore} from "../../app/rp_reducer";
import {ViewType} from "../../view_type/ViewType";

const searchElement = {
    title: "Szukaj ofert",
    icon: <SearchIcon size="2.2" />,
    onClick: () => {
        window.location.href = rpAppLink.offer.list() + "#search-focus";
    }
};

export const useNavigationSearch = () => {
    const viewType = useSelector((state: IRPStore) => state.viewType.current);

    const shouldShowSearchElement = viewType ? [ViewType.OFFER, ViewType.PROPERTY].includes(viewType) : false;

    return shouldShowSearchElement ? searchElement : null;
};
