import React, {ForwardedRef, forwardRef, useRef, useState} from "react";
import {css} from "@emotion/react";

import {p, position} from "@pg-design/helpers-css";
import {useCombinedRefs} from "@pg-mono/hooks";

import {TextInputBase} from "../../../atoms/TextInputBase";
import {IInputWrapperProps, InputWrapper} from "../../../atoms/wrapper/InputWrapper";
import {IFieldState} from "../../../types";
import {IInputCoreProps, InputCore} from "../../input/InputCore";

export type ITimeInputProps = IInputWrapperProps & Omit<IInputCoreProps, "type">;
export type ITimeInputOwnProps = ITimeInputProps & {inputForwardedRef?: ForwardedRef<HTMLInputElement>};

export const TimeInput = forwardRef<HTMLDivElement, ITimeInputOwnProps>((props, forwardedRef) => {
    const {fieldState, detail, onFocus, onBlur, className, inputForwardedRef, ...inputCoreProps} = props;
    const localRef = useRef<HTMLInputElement>(null);
    const inputRef = useCombinedRefs<HTMLInputElement>(inputForwardedRef, localRef);

    const [isFocused, setFocused] = useState(false);
    const [isDirty, setDirty] = useState(false);

    const events = {
        onFocus: () => {
            setFocused(true);
            setDirty(true);
        },
        onBlur: () => {
            setFocused(false);
        },
        onClick: (event: React.MouseEvent) => {
            event.stopPropagation();

            if (inputRef.current) {
                inputRef.current.focus();
            }
        }
    };

    const innerFieldState: IFieldState = isFocused ? "focused" : fieldState ? fieldState : "default";

    return (
        <TextInputBase onClick={events.onClick} fieldState={innerFieldState} ref={forwardedRef} className={className}>
            <InputWrapper fieldState={innerFieldState} detail={detail} css={[inputWrapperStyle]} noPadding>
                <InputCore
                    {...inputCoreProps}
                    type={isDirty ? "time" : "text"}
                    ref={inputRef}
                    onFocus={(event) => {
                        events.onFocus();
                        onFocus && onFocus(event);
                    }}
                    onBlur={(event) => {
                        events.onBlur();
                        onBlur && onBlur(event);
                    }}
                />
            </InputWrapper>
        </TextInputBase>
    );
});

const inputWrapperStyle = css`
    ${position("relative")};
    ${p(1.5, 1.5, 1.5, 2)};

    & > div {
        width: 100%;
    }
`;
