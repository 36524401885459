import {isAsyncThunkAction, isRejected, SerializedError} from "@reduxjs/toolkit";
import {Request, Response} from "express";
import {Middleware, UnknownAction} from "redux";

import {consoleError} from "@pg-mono/logger";
import {IPromiseMiddlewareOptions} from "@pg-mono/request";

import {IRPServicesMeta} from "../../store/types/IRPServicesMeta";

interface IRtkqMiddlewareOptions {
    createErrorStatusAction: (status: number) => UnknownAction;
    ssrUtils: IRPServicesMeta | null;
    notifyError: IPromiseMiddlewareOptions["notifyError"];
}

interface IAsyncThunkAction {
    type: string;
    meta: {
        arg?: {
            endpointName: string;
            originalArgs?: unknown;
        };
        baseQueryMeta?: {
            request?: Request;
            response?: Response;
        };
        requestId: string;
        requestStatus: "pending" | "fulfilled" | "rejected";
    };
    payload?: {
        status?: number | string;
    };
    error?: {
        message: string;
        name?: string;
        stack?: string;
    } & SerializedError;
}

export function createRtkqMiddleware(options: IRtkqMiddlewareOptions): Middleware {
    const {notifyError, ssrUtils} = options;

    return (store) => (next) => (action) => {
        const isNotAsyncThunkAction = !isRpAsyncThunkAction(action);
        const isConditionError = isRejected(action) && action.error.name === "ConditionError";
        if (isNotAsyncThunkAction || isConditionError) {
            return next(action);
        }

        if (isRejected(action) && action.error) {
            const errorStatus = getResponseErrorStatus(action.payload?.status);
            const errorName = getErrorName(action.payload?.status);
            const reqUrl = ssrUtils?.currentRoute.url || window?.location.href || "unknown";
            const endpointName = action.meta.arg?.endpointName || "unknown";

            consoleError(`rtkq-middleware-error ${errorStatus}`, action.error.message, ` ; url: ${reqUrl}, endpoint: `, endpointName);

            if (errorStatus === 504 || errorStatus === "5xx") {
                const errorStatus = action.payload?.status && typeof action.payload.status === "number" ? action.payload.status : 500;
                store.dispatch(options.createErrorStatusAction(errorStatus));
            }

            notifyError({...action.error, name: errorName, rtkEndpointName: endpointName}, `rtkqMiddleware: catch ${errorStatus}: ${reqUrl}`);
        }

        return next(action);
    };
}

function getResponseErrorStatus(initialStatus?: number | string) {
    if (initialStatus === 400) {
        return 400;
    }

    if (initialStatus === 401) {
        return 401;
    }

    if (initialStatus === 403) {
        return 403;
    }

    if (initialStatus === 404 || initialStatus === 410) {
        return 404;
    }

    if (initialStatus === 504) {
        return 504;
    }

    if (initialStatus && typeof initialStatus === "number" && initialStatus >= 500) {
        return "5xx";
    }

    return "unknown";
}

function getErrorName(status?: number | string) {
    if (status === 400) {
        return "Bad Request";
    }

    if (status === 401) {
        return "Unauthorized";
    }

    if (status === 403) {
        return "Forbidden";
    }

    if (status === 404 || status === 410) {
        return "Not Found";
    }

    if (status === 504) {
        return "Gateway Timeout";
    }

    if (status && typeof status === "number" && status >= 500) {
        return "Server Error";
    }

    if (status === "TIMEOUT_ERROR") {
        return "Response stalled";
    }

    return "Unknown Status Error";
}

function isRpAsyncThunkAction(action: unknown): action is IAsyncThunkAction {
    return isAsyncThunkAction(action);
}
