import React, {HTMLAttributeAnchorTarget} from "react";
import styled from "@emotion/styled";

import {Text} from "@pg-design/text";
import {FOOTER_TEST_IDS} from "@pg-mono/e2e-test-utils";

export interface IFooterMenuElement {
    title: string;
    url?: string;
    rel?: string;
    target?: HTMLAttributeAnchorTarget;
    titleAttribute?: string;
    onClick?: (e: React.MouseEvent<HTMLAnchorElement>) => void;
}

export const FooterMenuElement: React.FC<IFooterMenuElement> = (props) => {
    return (
        <FooterMenuLi data-testid={FOOTER_TEST_IDS.FOOTER_ELEMENT}>
            <FooterLink href={props.url} rel={props.rel} target={props.target} title={props.titleAttribute} onClick={props.onClick}>
                <Text variant="info_txt_1">{props.title}</Text>
            </FooterLink>
        </FooterMenuLi>
    );
};

const FooterMenuLi = styled.li`
    list-style-type: none;
`;

const FooterLink = styled.a`
    line-height: 4rem;
    display: inline-block;
    color: #fff;
    margin-bottom: 2.4rem;
    cursor: pointer;

    &:visited,
    &:hover,
    &:focus,
    &:active {
        color: #fff;
        text-decoration: underline;
    }

    @media (min-width: 1024px) {
        margin-bottom: 0.8rem;
        line-height: 1.9rem;
    }
`;
