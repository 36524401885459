import {forwardRef, MouseEvent, useRef, useState} from "react";
import {css} from "@emotion/react";

import {ph, pl, position, w100} from "@pg-design/helpers-css";
import {PlusIcon} from "@pg-design/icons";
import {Text} from "@pg-design/text";
import {useCombinedRefs} from "@pg-mono/hooks";

import {TextInputBase} from "../../atoms/TextInputBase";
import {IInputWrapperProps, InputWrapper} from "../../atoms/wrapper/InputWrapper";
import {IFieldState} from "../../types";
import {IInputCoreProps, InputCore} from "../input/InputCore";

export type IFileInputProps = IInputWrapperProps &
    IInputCoreProps & {
        fileName?: string;
    };
type IFileInputOwnProps = IFileInputProps;

export const FileInput = forwardRef<HTMLInputElement, IFileInputOwnProps>((props, forwardedRef) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const {fieldState, fileName, detail, onFocus, onBlur, className, value, ...inputCoreProps} = props;
    const localRef = useRef<HTMLInputElement>(null);
    const inputRef = useCombinedRefs<HTMLInputElement>(forwardedRef, localRef);

    const [isFocused, setFocused] = useState(false);

    const events = {
        onFocus: () => {
            setFocused(true);
        },
        onBlur: () => {
            setFocused(false);
        },
        onClick: (event: MouseEvent) => {
            event.stopPropagation();

            if (localRef.current) {
                localRef.current.focus();
            }
        }
    };

    const innerFieldState: IFieldState = isFocused ? "focused" : fieldState ? fieldState : "default";

    return (
        <TextInputBase onClick={events.onClick} fieldState={innerFieldState} className={className}>
            <InputWrapper
                fieldState={innerFieldState}
                detail={detail}
                // TODO it seems that css is not passed to styled components, adding props to InputWrapperBase as a workaround
                position="relative"
                rightElementCss={css`
                    cursor: pointer;
                `}
                rightElement={
                    <div css={[selectFileInfo]}>
                        <PlusIcon size="2.5" />
                        <Text variant="button_small" css={[ph(1.5)]}>
                            Wybierz plik
                        </Text>
                    </div>
                }
            >
                <div css={placeholder}>
                    <div css={[fileNameStyle]}>{fileName || props.placeholder}</div>
                </div>
                <InputCore
                    {...inputCoreProps}
                    ref={inputRef}
                    onFocus={(event) => {
                        events.onFocus();
                        onFocus && onFocus(event);
                    }}
                    onBlur={(event) => {
                        events.onBlur();
                        onBlur && onBlur(event);
                    }}
                    css={[inputFileStyle]}
                    type="file"
                />
            </InputWrapper>
        </TextInputBase>
    );
});

const inputFileStyle = css`
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    opacity: 0;
    cursor: pointer;
`;

const fileNameStyle = css`
    position: absolute;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 100%;
`;

const selectFileInfo = css`
    white-space: nowrap;
    ${pl(2)};
    cursor: pointer;
`;

const placeholder = css`
    ${(position("relative"), w100)};
    cursor: pointer;
`;
