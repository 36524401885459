import React from "react";
import {css} from "@emotion/react";
import styled from "@emotion/styled";

export type WrapperType = "circle" | "square";

interface IProps {
    children: React.ReactNode;
    wrapperColor: string;
    wrapperSize: string;
    wrapperType?: WrapperType;
    className?: string;
    onClick?: (event: React.MouseEvent) => void;
}

export const SvgIconWrapper: React.FC<IProps> = (props) => {
    const wrapperType = props.wrapperType || "circle";

    return (
        <IconWrapper {...props} wrapperType={wrapperType}>
            {props.children}
        </IconWrapper>
    );
};

const IconWrapper = styled.div<IProps>`
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: ${(props) => (props.wrapperType === "circle" ? "50%" : 0)};

    ${(props: {wrapperColor: string; wrapperSize: string}) => css`
        background-color: ${props.wrapperColor};
        min-width: ${`${props.wrapperSize}rem`};
        width: ${`${props.wrapperSize}rem`};
        height: ${`${props.wrapperSize}rem`};
    `};
`;
