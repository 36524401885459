const MAJOR_SECOND = 1.125;

const scaleFont = (scale: number) => (baseFontSizeRem: number) => (index: number) => Math.round(baseFontSizeRem * Math.pow(scale, index) * 1000) / 1000;

export const generateTypeScale = (scaleFN: (index: number) => number) => ({
    100: rem(scaleFN(-3)),
    200: rem(scaleFN(-2)),
    300: rem(scaleFN(-1)),
    400: rem(scaleFN(0)),
    500: rem(scaleFN(1)),
    600: rem(scaleFN(2)),
    700: rem(scaleFN(3)),
    800: rem(scaleFN(4)),
    900: rem(scaleFN(5)),
    1000: rem(scaleFN(6)),
    1100: rem(scaleFN(7)),
    1200: rem(scaleFN(8))
});

const rem = (pxval: number) => `${pxval}rem`;

export const majorSecondScaleFN = scaleFont(MAJOR_SECOND);
