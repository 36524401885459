export const ButtonVariant = {
    FILLED_PRIMARY: "filled_primary",
    FILLED_SECONDARY: "filled_secondary",
    OUTLINED_SECONDARY: "outlined_secondary",
    OUTLINED_SECONDARY_LIGHT: "outlined_secondary_light",
    NONE_SECONDARY: "none_secondary",
    STRETCHED_PRIMARY: "stretched_primary",
    HIGHLIGHT_PRIMARY: "highlight_primary",
    MAP_TOOLTIP: "map_tooltip",
    BRAND_ICON: "brand_icon",
    BANNER_PRIMARY: "banner_primary"
} as const;

export type IButtonVariantType = typeof ButtonVariant;
export type ButtonVariantUnionType = IButtonVariantType[keyof IButtonVariantType];
