import React, {useEffect, useMemo, useRef} from "react";
import {useDispatch, useSelector} from "react-redux";
import type {Dispatch, UnknownAction} from "redux";

import {withRouteHandler} from "../../client/with_route_handler";
import {afterRedirectOauthLogin} from "../auth/actions/after_redirect_ouath_login";
import {loadFavouritesToStore} from "../favourite/actions/load_favourites_to_store";
import {useSessionTracking} from "../hooks/use_session_tracking";
import {ISentLeadActions, setSentLeadValuesAction} from "../lead/reducers/sent_lead_reducer";
import {getSentLeadFormValuesFromStorage} from "../lead/utils/sent_lead_form_values";
import {Footer} from "../navigation/components/Footer";
import {Navigation} from "../navigation/components/Navigation";
import {setNavigationState} from "../navigation/state/navigation_slice";
import {Notifications} from "../notifications/components/Notifications";
import {tdaDesktopHeight} from "../smart_ad_server/constants/tda_desktop_height";
import {tdaPortalId} from "../smart_ad_server/constants/tda_portal_id";
import {ViewType} from "../view_type/ViewType";
import {Awards} from "./components/Awards";
import {BrowserSupport} from "./components/BrowserSupport";
import {ScrollToTop} from "./components/ScrollToTop";
import {TdaServerSide} from "./components/TdaServerSide";
import {IVisualRoute} from "./types/IVisualRoute";
import {Routes} from "./Routes";
import {IRPStore} from "./rp_reducer";

export const Layout = withRouteHandler((props: {routes: IVisualRoute[]}) => {
    const dispatch: Dispatch<ISentLeadActions | UnknownAction> = useDispatch();

    const isAuthenticated = useSelector((state: IRPStore) => state.isAuthenticated);

    const isAuthenticatedRef = useRef(isAuthenticated);

    useSessionTracking();

    useEffect(() => {
        if (isAuthenticatedRef.current !== isAuthenticated) {
            //  This is the place to add any global logic that requires to run on authentication status change

            dispatch(loadFavouritesToStore());
        }

        isAuthenticatedRef.current = isAuthenticated;
    }, [isAuthenticated]);

    useEffect(() => {
        dispatch(afterRedirectOauthLogin(isAuthenticated));

        /**
         * State of sentLeadFormValues is based on localStorage, so we need to add it after first mount in client.
         * It's because localStorage is not available on the server, so it will be undefined, and we need to avoid
         * ssr error (JSX mismatch).
         */

        const sentLeadValuesFromStorage = getSentLeadFormValuesFromStorage();

        if (sentLeadValuesFromStorage) {
            dispatch<ISentLeadActions>(setSentLeadValuesAction(sentLeadValuesFromStorage));
        }

        //some users have two cookies "algolytics_uuid", this affects the assignment of recommendations to users, recommendations are assigned to "algolytics_uuuid" if they are assigned to the wrong uuid they will not be visible in the konto-mieszkaniowe
        document.cookie = "algolytics_uuid=; Domain=.rynekpierwotny.pl; Expires=Thu, 01 Jan 1970 00:00:00 GMT; Path=/";
    }, []);

    const pinHandlers = useMemo(
        () => ({
            onPin: () => dispatch(setNavigationState("pinned")),
            onUnpin: () => dispatch(setNavigationState("unpinned")),
            onUnfix: () => dispatch(setNavigationState("unfixed"))
        }),
        [dispatch, setNavigationState]
    );

    const viewType = useSelector((state: IRPStore) => state.viewType.current);
    const blendTopOffset = viewType === ViewType.OFFER_LIST ? `${tdaDesktopHeight}px` : undefined;

    return (
        <>
            <ScrollToTop />
            <BrowserSupport />

            <Notifications />

            <div id={tdaPortalId}>{viewType === ViewType.OFFER_LIST && <TdaServerSide />}</div>
            <Navigation onPin={pinHandlers.onPin} onUnpin={pinHandlers.onUnpin} onUnfix={pinHandlers.onUnfix} blendTopOffset={blendTopOffset} />
            <main>
                <Routes routes={props.routes} />
            </main>
            <Awards />
            <Footer />
        </>
    );
});
