import React from "react";
import {Global} from "@emotion/react";

interface IProps {
    lockBodyScroll?: boolean;
}

export const ModalGlobalStyles = (props: IProps) => {
    return (
        <Global
            styles={{
                body: {
                    ...(props.lockBodyScroll ? {overflow: "hidden"} : {})
                }
            }}
        />
    );
};
