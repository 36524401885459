import {useTheme} from "@emotion/react";

import {IFieldState} from "../types";

export const useFieldStateColor = () => {
    const theme = useTheme();

    return {
        getFieldStateColor: (fieldState?: IFieldState) => {
            switch (fieldState) {
                case "error":
                    return theme.colors.danger;

                case "focused":
                    return theme.colors.info;

                default:
                    return theme.colors.gray["600"];
            }
        }
    };
};
