import React, {FC} from "react";
import {css} from "@emotion/react";
import styled from "@emotion/styled";

import {Loader} from "@pg-design/loader";

import {withIconVariantStyles, withTextVariantStyles} from "../hoc";
import {ButtonVariant, ButtonVariantUnionType} from "../utils/button_variants";
import {getButtonStyle} from "../utils/WithButtonVariant";

export type IButtonSizePrimary = "big" | "medium" | "small" | "x-small";
export type IButtonSizeBrandIcon = "big" | "medium";
export type IIcon = React.ComponentType<{size: string; className?: string; fill: string}>;
export type IBrandIcon = React.ComponentType<{
    size: string;
    className?: string;
    wrapperColor?: string;
    wrapperSize?: string;
    wrapperType?: "circle" | "square";
}>;

interface ICommonProps {
    children?: React.ReactNode;
    className?: string;
    onClick?: (e: React.MouseEvent<HTMLElement>) => void;
    disabled?: boolean;
    dataTestId?: string;
    iconLeft?: IIcon;
    iconRight?: IIcon;
    isLoading?: boolean;
    type?: "button" | "submit" | "reset";

    //button can be used as="div" or link (pass 'href' prop or specify as="a")
    as?: "a" | "button" | "div";
    href?: string;
    target?: string;
    title?: string;
    rel?: string;
}

interface IButtonCommonProps extends ICommonProps {
    variant: Exclude<
        ButtonVariantUnionType,
        "filled_primary" | "brand_icon" | "outlined_secondary" | "outlined_secondary_light" | "none_secondary" | "banner_primary"
    >;
    size?: never;
}

// Only some buttons have size property
interface IButtonPrimary extends ICommonProps {
    variant:
        | "filled_primary"
        | "none_secondary"
        | "highlight_primary"
        | "outlined_secondary"
        | "outlined_secondary_light"
        | "filled_secondary"
        | "banner_primary";
    size?: IButtonSizePrimary;
}

type IButtonBrandIcon = ICommonProps & {
    variant: "brand_icon";
    size?: IButtonSizeBrandIcon;
    iconLeft?: IBrandIcon;
    iconRight?: IBrandIcon;
};

export type IButtonProps = IButtonCommonProps | IButtonPrimary | IButtonBrandIcon;

export const Button: FC<IButtonProps> = (props) => {
    const {
        as = "button",
        variant = ButtonVariant.FILLED_PRIMARY,
        size,
        iconLeft: IconLeft,
        iconRight: IconRight,
        href,
        dataTestId,
        children,
        className,
        onClick,
        disabled,
        isLoading,
        target,
        title,
        type,
        rel
    } = props;
    const variantStyle = getButtonStyle(variant, size);

    const Component = href ? "a" : as;
    const hasIcon = !!IconLeft || !!IconRight;
    const hasText = !!children;

    const IconLeftVariant = IconLeft ? withIconVariantStyles(IconLeft)(variant, "left", hasText, size) : undefined;
    const IconRightVariant = IconRight ? withIconVariantStyles(IconRight)(variant, "right", hasText, size) : undefined;
    const TextVariant = withTextVariantStyles(variant, size);

    return (
        <ButtonBase
            as={Component}
            data-testid={dataTestId}
            size={size}
            className={className}
            onClick={onClick}
            disabled={disabled}
            isLoading={isLoading}
            href={href}
            target={href && target}
            rel={href && rel}
            title={title}
            icon={hasIcon}
            css={variantStyle}
            type={type || "submit"}
        >
            <ButtonContentWrapper isLoading={isLoading} isIcon={hasIcon}>
                {!isLoading && (
                    <>
                        {IconLeftVariant && IconLeftVariant}
                        <TextVariant>{children}</TextVariant>
                        {IconRightVariant && IconRightVariant}
                    </>
                )}
            </ButtonContentWrapper>

            {isLoading && <Loader scaleToContainer />}
        </ButtonBase>
    );
};

const transitionStyle = css`
    opacity: 1;
    transition-property: box-shadow, color, background-color, opacity, fill;
    transition-duration: 0.15s;
    transition-timing-function: ease-in-out;
`;

interface IButtonBase {
    className?: string;
    onClick?: (e: React.MouseEvent<HTMLElement>) => void;
    disabled?: boolean;
    dataTestId?: string;
    icon?: React.ReactNode;
    isLoading?: boolean;
    loaderIcon?: JSX.Element;
    size?: IButtonSizePrimary | IButtonSizeBrandIcon | undefined;

    //button can be used as="div" or link (pass 'href' prop or specify as="a")
    as?: "a" | "button" | "div";
    href?: string;
    target?: string;
    rel?: string;
    title?: string;
}
const ButtonBase = styled.button<IButtonBase>`
    // prevent Safari from showing rounded corners and gradient backgrounds.
    -webkit-appearance: none !important;
    overflow: hidden;

    position: relative;
    display: inline-flex;
    margin-bottom: 0;
    outline-color: ${(props) => props.theme.colors.info};

    ${transitionStyle};

    ${(props) =>
        props.as !== "button" &&
        css`
            text-align: center;
        `}

    justify-content: center;
    white-space: nowrap;
    align-items: center;
    touch-action: manipulation;
    cursor: pointer;
    border-width: 1px;
    border-style: solid;
    height: 5.6rem;
    user-select: none;
    padding: 1.6rem;
    border-radius: 18rem;

    &:hover {
        filter: saturate(0.75);
    }

    &:disabled {
        cursor: not-allowed;
    }
`;

const ButtonContentWrapper = styled.span<{isLoading?: boolean; isIcon?: boolean}>`
    ${transitionStyle};

    ${(props) =>
        props.isIcon &&
        css`
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
        `}
`;
