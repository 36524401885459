import React from "react";
import classNames from "classnames";

import {IIcon} from "../interfaces/IIcon";
import {SvgIconWrapper} from "./SvgIconWrapper";

import {flagIcon} from "./FlagIcon.module.css";

export const FlagIcon: React.FC<IIcon> = (props) => {
    const hasWrapper = props.wrapperSize && props.wrapperColor;
    const size = props.size ? {"--iconSize": props.size} : {"--iconSize": "1"};
    const iconStyle = {viewBox: "0 0 10 10", fill: "none", ...size};

    const flagIconCN = classNames(flagIcon, props.className);

    const icon = (
        <svg onClick={!hasWrapper ? props.onClick : undefined} className={!hasWrapper ? flagIconCN : flagIcon} style={iconStyle}>
            {props.children}
        </svg>
    );

    return props.wrapperSize && props.wrapperColor ? (
        <SvgIconWrapper
            onClick={props.onClick}
            className={props.className}
            wrapperColor={props.wrapperColor}
            wrapperSize={props.wrapperSize}
            wrapperType={props.wrapperType}
        >
            {icon}
        </SvgIconWrapper>
    ) : (
        icon
    );
};
