import {createSlice, PayloadAction} from "@reduxjs/toolkit";

export const offerListingSlice = createSlice({
    name: "offerListing",
    initialState: {
        isListingNavigationSticky: false
    },
    reducers: {
        setOfferListingNavigationSticky: (state, action: PayloadAction<boolean>) => {
            state.isListingNavigationSticky = action.payload;
        }
    }
});

export const {setOfferListingNavigationSticky} = offerListingSlice.actions;
