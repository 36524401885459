import {IAppError, Response5xx, Response400} from "@pg-mono/request/src/error/response_error";
import {apiPath, apiV2ListPath, apiV2Path} from "@pg-mono/rp-api-routes";

import {notifyBugsnag} from "../bugsnag/notify_bugsnag";
import {getErrorGroupHash} from "./get_error_group_hash";
import {getMatchedPathDefinition} from "./get_matched_path";

const allApiPaths = {
    apiPath,
    apiV2Path,
    apiV2ListPath
};

export const getBugsnagErrorBody = (err: {message: string; name: string; appError?: IAppError}) => {
    const shouldParseError = [Response5xx, Response400].some((error) => err instanceof error);
    if (!shouldParseError) {
        return err;
    }

    // manually creating error-like object so bugsnag has something more readable to report

    try {
        // try...catch block because `new URL()` will throw `TypeError` if something goes wrong, ie. when received string cannot be converted to url
        const url = new URL(err.message);
        const scenario = url.searchParams.get("s");
        const appErrorString = err.appError ? JSON.stringify(err.appError) : "";
        const errorGroupingHash = getErrorGroupHash(`${url}${scenario}${appErrorString}`);

        const matchedPath = getMatchedPathDefinition(allApiPaths, url.pathname);

        if (err.message && url && url.pathname) {
            return {
                name: `${err.name}: ${matchedPath?.path || url.pathname}, scenario: ${scenario}`, // ie. "Server Error: domain.pl/offers/coordinates/"
                message: err.message,
                ...(errorGroupingHash ? {groupingHash: errorGroupingHash} : {})
            };
        }
    } catch (caughtError) {
        // notify about additional error
        notifyBugsnag(
            {
                name: "getValidErrorBody",
                message: "Error while building error body"
            },
            "Error while building error body",
            {originalError: err, caughtError}
        );
    }

    return err;
};
