import React from "react";
import {css} from "@emotion/react";

import {display, pv} from "@pg-design/helpers-css";
import {useBlurOutside} from "@pg-mono/hooks";

import {InputRangeOptions} from "./InputRangeOptions";

export interface IRange<T> {
    lower: T;
    upper: T;
    bounds?: string;
}

export enum Direction {
    UP = "UP",
    DOWN = "DOWN"
}

type RangeType = string | number;

interface IFieldProps<TKey, TValue> {
    name?: TKey;
    value?: TValue;
    error?: string | string[];
    onChange?: React.ChangeEventHandler<HTMLInputElement>;
    checked: boolean;
}

interface ICreateRangeInnerComponentProps extends Omit<IFieldProps<string, React.InputHTMLAttributes<HTMLInputElement>["value"]>, "checked"> {
    roundingDirection?: Direction;
    roundToMultiplicationOf?: number;
    groupClassName?: string;
    placeholder?: string;
    type?: string;
    pattern?: string;
    maxNumberLength?: number;
    callAfterChangeOnEnter?: boolean;
    className?: string;
    onKeyPress?: React.KeyboardEventHandler<HTMLInputElement>;
    min?: number | string;
}

export interface RangeProps extends Omit<IFieldProps<string, IRange<RangeType>>, "checked" | "onChange"> {
    disabled?: boolean;
    className?: string;
    groupClassName?: string;
    options?: {value: number; label: string}[];
    hasDashSeparator?: boolean;
    errorOnBottom?: boolean;
    callAfterChangeOnEnter?: boolean;
    roundToMultiplicationOf?: number;
    onChange: (fieldName: string, value: IRange<RangeType>) => void;
}

/*
 * TODO: Check if commented props are needed for InnerComponent
 * On lower level commented props are added to native HTML input tag - this results in a warning
 */
export const createRange = (InnerComponent: React.ComponentType<ICreateRangeInnerComponentProps>) => (props: RangeProps) => {
    const onChange = (name: string, value: RangeType) => {
        const currentValue: IRange<RangeType> = {
            lower: props.value?.lower || "",
            upper: props.value?.upper || "",
            bounds: props.value?.bounds
        };

        if (name === `${props.name}__lower`) {
            props.onChange(`${props.name}`, {...currentValue, lower: value});
        }
        if (name === `${props.name}__upper`) {
            props.onChange(`${props.name}`, {...currentValue, upper: value});
        }
    };

    const createOnChange =
        (name: string): ICreateRangeInnerComponentProps["onChange"] =>
        (event) => {
            const value = event.currentTarget.value;
            onChange(name, value);
        };

    const preventNonDigitChars = (event: React.KeyboardEvent) => {
        const notAllowedCharCodes = ["Minus", "Period", "NumpadAdd", "NumpadSubtract", "Comma", "Equal"];

        if (notAllowedCharCodes.includes(event.code)) {
            event.preventDefault();
        }
    };

    const {name, value} = props;

    const {
        isFocused: isRangeInputLowerFocused,
        setIsFocused: setRangeInputLowerIsFocused,
        wrapperProps: inputLowerWrapperProps,
        targetElementProps: inputLowerBlurProps
    } = useBlurOutside<HTMLDivElement>();
    const {
        isFocused: isRangeInputUpperFocused,
        setIsFocused: setRangeInputUpperIsFocused,
        wrapperProps: inputUpperWrapperProps,
        targetElementProps: inputUpperBlurProps
    } = useBlurOutside<HTMLDivElement>();

    return (
        <div css={[rangeHolder]} className={props.className}>
            <div {...inputLowerWrapperProps} css={inputHolder} className="range-input-lower">
                <InnerComponent
                    {...inputLowerBlurProps}
                    name={`${name}__lower`}
                    value={value && value.lower}
                    error={undefined}
                    onChange={createOnChange(`${name}__lower`)}
                    // onAfterChange={onAfterChange}
                    className={props.groupClassName}
                    placeholder="Od"
                    type="number"
                    pattern="[0-9]*"
                    min="0"
                    onKeyPress={preventNonDigitChars}
                    // maxNumberLength={8}
                    // roundToMultiplicationOf={props.roundToMultiplicationOf}
                    // roundingDirection={Direction.DOWN}
                    // callAfterChangeOnEnter={props.callAfterChangeOnEnter}
                />
                <InputRangeOptions
                    value={props.value}
                    options={props.options}
                    onChange={onChange}
                    onClick={() => setRangeInputLowerIsFocused(false)}
                    name={`${name}__lower`}
                    cutDirection={1}
                    css={[inputOptionsWrapper, isRangeInputLowerFocused ? "" : display("none")]}
                />
            </div>

            {props.hasDashSeparator && <span css={separatorStyle}>-</span>}

            <div {...inputUpperWrapperProps} css={inputHolder} className="range-input-upper">
                <InnerComponent
                    {...inputUpperBlurProps}
                    name={`${name}__upper`}
                    value={value && value.upper}
                    error={undefined}
                    onChange={createOnChange(`${name}__upper`)}
                    // onAfterChange={onAfterChange}
                    className={props.groupClassName}
                    placeholder="Do"
                    type="number"
                    pattern="[0-9]*"
                    min="0"
                    onKeyPress={preventNonDigitChars}
                    // maxNumberLength={8}
                    // roundToMultiplicationOf={props.roundToMultiplicationOf}
                    // roundingDirection={Direction.UP}
                    // callAfterChangeOnEnter={props.callAfterChangeOnEnter}
                />
                <InputRangeOptions
                    value={props.value}
                    options={props.options}
                    onChange={onChange}
                    onClick={() => setRangeInputUpperIsFocused(false)}
                    name={`${name}__upper`}
                    cutDirection={-1}
                    css={[inputOptionsWrapper, isRangeInputUpperFocused ? "" : display("none")]}
                />
            </div>
        </div>
    );
};

const rangeHolder = css`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    max-width: 100%;

    span {
        margin-bottom: 1rem;
    }
`;

const inputHolder = css`
    display: inline-flex;
    flex-direction: column;
    margin-right: 1rem;
    margin-left: 1rem;
    flex: 1 1 auto;

    &.range-input-lower {
        margin-left: 0;
    }

    &.range-input-upper {
        margin-right: 0;
    }
`;

const separatorStyle = css`
    ${pv(1.5)};
`;

const inputOptionsWrapper = css`
    background: #fff;
    z-index: 2; // 2 to display over other normal elements
`;
