import {css, Theme} from "@emotion/react";

import {fadeInAnimation, onDesktop} from "@pg-design/helpers-css";

import {IModalProps} from "../../../types/IModalProps";

export const fullScreenStyle = (fullScreen: IModalProps["fullScreen"]) => (theme: Theme) => {
    switch (fullScreen) {
        case "xs":
            return css`
                inset: 0;
                ${fadeInAnimation("0.1s")};

                @media (min-width: ${theme.breakpoints.sm}) {
                    inset: unset;
                    animation: none;
                    opacity: 1;
                }
            `;
        case "xs,sm":
            return css`
                inset: 0;
                ${fadeInAnimation("0.1s")};

                ${onDesktop(css`
                    inset: unset;
                    animation: none;
                    opacity: 1;
                `)};
            `;

        case true:
            return css`
                inset: 0;
                ${fadeInAnimation("0.1s")};
            `;

        default:
            return "";
    }
};
