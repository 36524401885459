import React from "react";
import {Props as ReactModalProps} from "react-modal";
import {css, Theme} from "@emotion/react";
import {rgba} from "emotion-rgba";

import {zIndex} from "@pg-design/helpers-css";

import {IModalProps} from "../../types/IModalProps";
import {getPropsWithDefault} from "../../utils/get_props_with_default";
import {modalAlignStyle} from "./styles/modal_align_style";
import {modalValignStyle} from "./styles/modal_valign_style";

export const overlayElement =
    (baseModalProps: IModalProps): ReactModalProps["overlayElement"] =>
    (props, children) => {
        const {align, vAlign, zIndex: zIndexValue} = getPropsWithDefault(baseModalProps);

        // style props should be undefined to eliminate default styles
        return (
            <div
                {...props}
                style={undefined}
                css={[overlayStyle, baseModalProps.overlayStyle, modalValignStyle(vAlign), modalAlignStyle(align), zIndex(zIndexValue || 0)]}
            >
                {children}
            </div>
        );
    };

const overlayStyle = (theme: Theme) => css`
    position: fixed;
    inset: 0;
    background-color: ${rgba(theme.colors.secondary, 0.75)};
    display: flex;
`;
