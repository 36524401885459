import {IFetchContext} from "@pg-mono/data-fetcher";

import {IRPStore} from "../../../app/rp_reducer";
import {IRPRequestMeta} from "../../../app/rp_request_meta";
import {IMetaDataOptions, MetaDataDispatch, updateMetaData} from "../../../meta_data/actions/update_meta_data";
import {OfferMetaRobots} from "../../constants/offer_meta_robots";

export const setOfferListMetaDataDefaults = (ctx: IFetchContext<IRPRequestMeta>) => async (dispatch: MetaDataDispatch, getState: () => IRPStore) => {
    const state = getState().metaData;

    dispatch(
        updateMetaData({
            ...state.standardMetaData,
            pathname: ctx.route.pathname,
            lang: state.standardMetaData.lang as IMetaDataOptions["lang"],
            robots: OfferMetaRobots.NOINDEX
        })
    );

    return ctx.prevResult;
};
