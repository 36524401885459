import React from "react";
import {Props as ReactModalProps} from "react-modal";
import {css} from "@emotion/react";

import {IModalProps} from "../../types/IModalProps";
import {getPropsWithDefault} from "../../utils/get_props_with_default";
import {borderRadiusStyle} from "./styles/border_radius_style";
import {fullScreenStyle} from "./styles/full_screen_style";

export const contentElement =
    (baseModalProps: IModalProps): ReactModalProps["contentElement"] =>
    (props, children) => {
        const {fullScreen, borderRadius: borderRadiusValue} = getPropsWithDefault(baseModalProps);

        // style props should be undefined to eliminate default styles
        return (
            <div
                {...props}
                style={undefined}
                css={[modalStyles, baseModalProps.contentStyle, borderRadiusStyle(fullScreen, borderRadiusValue), fullScreenStyle(fullScreen)]}
            >
                {children}
            </div>
        );
    };

const modalStyles = css`
    position: absolute;
    background-color: #ffffff;
    overflow: auto;
    outline: none;
    padding: 0;
    max-height: 100vh;
    display: flex;
    flex-direction: column;
`;
