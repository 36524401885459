export const objectsDiff = <TObject extends {[key in string]: unknown}>(oldObject: TObject, newObject: TObject): Partial<TObject> | null => {
    const result = {} as Partial<TObject>;

    if (newObject === oldObject || newObject === null) {
        return null;
    }

    Object.keys(newObject).forEach((newObjectKey: keyof TObject) => {
        if (typeof newObject[newObjectKey] === "object") {
            const subStructureDiff = objectsDiff(oldObject?.[newObjectKey] as TObject, newObject[newObjectKey] as TObject);

            if (subStructureDiff) {
                // return whole new array if difference has been detected
                result[newObjectKey] = Array.isArray(newObject[newObjectKey]) ? newObject[newObjectKey] : (subStructureDiff as TObject[keyof TObject]);
            }
        } else if (oldObject?.[newObjectKey] !== newObject[newObjectKey]) {
            result[newObjectKey] = newObject[newObjectKey];
        }
    });

    return Object.keys(result).length > 0 ? result : null;
};
