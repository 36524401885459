import React from "react";
import {useTheme} from "@emotion/react";

import {textAlign} from "@pg-design/helpers-css";

import {getInputStyle} from "../../atoms/get_input_style";

export type IInputCoreProps = React.InputHTMLAttributes<HTMLInputElement> & {
    testid?: string;
    inputtextalign?: "center" | "end" | "justify" | "left" | "match-parent" | "right" | "start";
};

export const InputCore = React.forwardRef<HTMLInputElement, IInputCoreProps>((props, forwardedInputRef) => {
    const {inputtextalign = "left"} = props;
    const theme = useTheme();
    const inputStyle = getInputStyle(theme, true, props.disabled);

    return (
        <input
            css={[inputStyle, textAlign(inputtextalign)]}
            {...props}
            type={props.type}
            disabled={props.disabled}
            ref={forwardedInputRef}
            data-testid={props.testid}
        />
    );
});
