import {css} from "@emotion/react";

import {borderRadius, onDesktop} from "@pg-design/helpers-css";

import {IModalProps} from "../../../types/IModalProps";

export const borderRadiusStyle = (fullScreen: IModalProps["fullScreen"], borderRadiusValue: IModalProps["borderRadius"]) => {
    if (fullScreen === true) {
        return "";
    }

    if (typeof borderRadiusValue === "object") {
        return css`
            ${borderRadius(borderRadiusValue.mobile || 0)};

            ${onDesktop(borderRadius(borderRadiusValue.desktop || 0))};
        `;
    }

    if (typeof borderRadiusValue === "number") {
        return css`
            ${borderRadius(borderRadiusValue)};
        `;
    }

    return "";
};
