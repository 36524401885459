import React, {PropsWithChildren, ReactNode} from "react";
import {css, Theme} from "@emotion/react";

import {Button} from "@pg-design/button";
import {calculateRemSize, flexJustifyCenter} from "@pg-design/helpers-css";
import {Text} from "@pg-design/text";

import {ModalCloseButton} from "../atoms/ModalCloseButton";

interface IProps extends PropsWithChildren {
    header?: ReactNode;
    onOkClick: () => void;
    onCancelClick: () => void;
    okButtonText?: string | false;
    cancelButtonText?: string | false;
    className?: string;
}

export const ModalLayoutPrompt = (props: IProps) => {
    return (
        <div css={wrapperStyle} className={props.className}>
            <Text variant="headline_3">{props.header}</Text>
            <ModalCloseButton css={modalCloseButtonStyle} />

            {props.children ? <div>{props.children}</div> : null}

            <div css={buttonsWrapperStyle}>
                {props.okButtonText !== false && (
                    <Button variant="filled_primary" css={buttonStyle} onClick={props.onOkClick}>
                        {props.okButtonText ?? "OK"}
                    </Button>
                )}
                {props.cancelButtonText !== false && (
                    <Button variant="outlined_secondary" css={buttonStyle} onClick={props.onCancelClick}>
                        {props.cancelButtonText ?? "Anuluj"}
                    </Button>
                )}
            </div>
        </div>
    );
};

const wrapperStyle = (theme: Theme) => css`
    padding: 2rem 5rem 2rem 2rem;
    display: flex;
    flex-direction: column;
    gap: ${calculateRemSize(2)};
    overflow-y: auto;
    overflow-x: hidden;

    @media (min-width: ${theme.breakpoints.sm}) {
        max-width: 50rem;
    }
`;

const modalCloseButtonStyle = css`
    position: absolute;
    inset: ${calculateRemSize(1)} ${calculateRemSize(1)} auto auto;
    cursor: pointer;
`;

const buttonsWrapperStyle = (theme: Theme) => css`
    ${flexJustifyCenter};
    flex-direction: column;
    gap: ${calculateRemSize(2)};

    @media (min-width: ${theme.breakpoints.sm}) {
        flex-direction: row;
    }
`;

const buttonStyle = (theme: Theme) => css`
    width: 100%;

    @media (min-width: ${theme.breakpoints.sm}) {
        width: 20rem;
    }
`;
