import {useMemo} from "react";
import {GroupBase, StylesConfig, Theme} from "react-select";
import {useTheme} from "@emotion/react";

interface IHook<Option = unknown, IsMulti extends boolean = boolean, Group extends GroupBase<Option> = GroupBase<Option>> {
    getTheme: (theme: Theme) => Theme;
    styles: StylesConfig<Option, IsMulti, Group>;
}

export const useSelectTheme = <Option = unknown, IsMulti extends boolean = boolean, Group extends GroupBase<Option> = GroupBase<Option>>(): IHook<
    Option,
    IsMulti,
    Group
> => {
    const theme = useTheme();

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const getTheme = ({colors, spacing, ...themes}: Theme): Theme => {
        return {
            colors: {
                ...colors,
                neutral20: theme.colors.gray["600"],
                neutral30: theme.colors.gray["600"],
                primary: theme.colors.info,
                primary25: theme.colors.gray["200"]
            },
            spacing: {...spacing, controlHeight: 48},
            borderRadius: 8
        };
    };

    const styles: StylesConfig<Option, IsMulti, Group> = useMemo(() => {
        return {
            control: (provided, state) => {
                return {
                    ...provided,
                    backgroundColor: state.isDisabled ? theme.colors.gray["100"] : provided.backgroundColor,
                    ["&:hover"]: {...(provided["&:hover"] as object), boxShadow: "0px 4px 10px rgba(174, 177, 180, 0.3)"}
                };
            },
            indicatorSeparator: () => {
                return {display: "none"};
            },
            menu: (provided) => {
                return {...provided, padding: "0.8rem", zIndex: 1000};
            },
            noOptionsMessage: (provided) => {
                return {...provided, color: theme.colors.secondary, padding: "0.8rem"};
            },
            option: (provided, state) => {
                return {
                    ...provided,
                    backgroundColor: state.isSelected ? "#fff" : provided.backgroundColor,
                    color: state.isSelected ? theme.colors.secondary : provided.color
                };
            },
            placeholder: (provided, state) => {
                return {...provided, color: state.isDisabled ? theme.colors.gray["300"] : theme.colors.secondary};
            },
            valueContainer: (provided) => {
                return {...provided, padding: "0 1.6rem"};
            }
        };
    }, []);

    return {
        getTheme,
        styles
    };
};
