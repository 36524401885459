import {IFieldState} from "./types";

interface IGetFieldStateProps {
    error?: string | string[];
    disabled?: boolean;
    fieldState?: IFieldState;
}

export const getFieldState = ({error, disabled, fieldState}: IGetFieldStateProps): IFieldState => {
    if (fieldState) {
        return fieldState;
    }

    switch (true) {
        case !!error:
            return "error";
        case disabled:
            return "disabled";
        default:
            return "default";
    }
};
