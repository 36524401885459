import {rpAppLink} from "@pg-mono/rp-routes";

const defX1 = require("../images/def_x1.png");
const defX2 = require("../images/def_x2.png");
const defX3 = require("../images/def_x3.png");
const abroadX1 = require("../images/abroad_x1.png");
const abroadX2 = require("../images/abroad_x2.png");
const abroadX3 = require("../images/abroad_x3.png");
const defaultMobile = require("../images/baner_konf_mobile.jpg");
const abroadMobile = require("../images/baner_spain_mobile.jpg");

export const tdaInternalBanners: {
    imgSet: string[];
    mobileImg: string;
    link: string;
}[] = [
    {
        imgSet: [defX1, defX2, defX3],
        mobileImg: defaultMobile,
        link: rpAppLink.propertyConfigurator()
    },
    {
        imgSet: [abroadX1, abroadX2, abroadX3],
        mobileImg: abroadMobile,
        link: rpAppLink.investmentOffer.investmentCategory.subcategory.base({category: "za-granica", subcategory: "hiszpania"})
    }
];
