import {createContext} from "react";

export const SystemModalContext = createContext<{
    variant: ISystemModalVariant;
    systemTheme: ISystemModalTheme;
    headerVariant?: IHeaderVariant;
}>({variant: "compact", systemTheme: "light", headerVariant: "inherit"});

export type ISystemModalVariant = "spacious" | "compact" | "fit" | "medium";
export type ISystemModalTheme = "dark" | "light";
export type IHeaderVariant = "gray" | "inherit";

export const SystemModalContextProvider = SystemModalContext.Provider;
