import React, {ChangeEvent, ForwardedRef, forwardRef, ReactNode, useEffect, useRef, useState} from "react";
import {css} from "@emotion/react";

import {calculateRemSize} from "@pg-design/helpers-css";
import {useCombinedRefs} from "@pg-mono/hooks";

import {FieldWrapper, IFieldWrapperProps} from "../../../atoms/wrapper/FieldWrapper";
import {getFieldState} from "../../../field_state_helper";
import {getBase64FromFile} from "../../../utils/get_base64_from_file";
import {FieldMessage} from "../../FieldMessage";
import {FileInput, IFileInputProps} from "../../file_field/FileInput";
import {IImagePreviewProps, ImageFieldPreview} from "./ImageFieldPreview";

/**
 *     ImageField
 *
 *     This field is meant to be a controlled field:
 *         - images are kept as strings, because we receive them as url or pass them as base64,
 *         - there is no way to set value of <input type="file" /> based on provided data, for example,
 *           it's not possible to create a new File and set it as a value
 */

export type IImageFieldProps = IFieldWrapperProps &
    Omit<IFileInputProps, "fileName" | "onChange" | "value"> & {
        value: string;
        onChange: (image: string | null) => void;
        error?: string;
        hideInput?: boolean;
        children?: ReactNode;
        //  Providing preview config enables image preview
        preview?: Omit<IImagePreviewProps, "file" | "imgSrc">;
    };

export const ImageField = forwardRef((props: IImageFieldProps, ref: ForwardedRef<HTMLInputElement>) => {
    const {children, labelContent, message, id, className, error, value, hideInput, preview, onChange, ...inputProps} = props;
    const [file, setFile] = useState<File | null>(null);
    const [filename, setFilename] = useState<string | undefined>(undefined);

    const localRef = useRef<HTMLInputElement>(null);
    const inputRef = useCombinedRefs<HTMLInputElement>(ref, localRef);

    useEffect(() => {
        if (value) {
            const fileName = value.split("/").pop();
            /*
                TODO: don't set filename from value if base64
             */
            setFilename(fileName);
        } else {
            setFilename(undefined);
        }

        onChange(value);
    }, [value]);

    useEffect(() => {
        (async () => {
            if (file) {
                const image = await getBase64FromFile(file);
                setFilename(file.name);
                onChange(image);
            }
        })();

        if (!file && !value) {
            onChange(null);
        }
    }, [file]);

    const onFileChange = (e: ChangeEvent<HTMLInputElement>) => {
        const file = e.currentTarget.files && e.currentTarget.files[0];

        setFile(file || null);

        /*
            On each change, input value should be reseted, because onChange event won't trigger, if
            a file path is the same as before (when uploading the same file after removal)
         */
        if (localRef.current) {
            localRef.current.value = "";
        }
    };

    const removeImage = () => {
        if (preview && preview.removeImage) {
            preview.removeImage();
            setFile(null);
            setFilename(undefined);
        }
    };

    const fieldState = getFieldState(props);

    return (
        <div css={fieldWrap} className={className}>
            {preview && <ImageFieldPreview {...preview} imgSrc={value} removeImage={removeImage} />}

            {hideInput && (error || message) && <FieldMessage fieldState={fieldState}>{error ? error : message}</FieldMessage>}

            {children}

            {!hideInput && (
                <FieldWrapper labelContent={labelContent} fieldState={fieldState} message={error || message} htmlFor={id}>
                    <FileInput
                        {...inputProps}
                        ref={inputRef}
                        id={id}
                        fieldState={fieldState}
                        fileName={filename}
                        onChange={onFileChange}
                        css={css`
                            cursor: pointer;
                        `}
                    />
                </FieldWrapper>
            )}
        </div>
    );
});

//  Styles
const fieldWrap = css`
    display: flex;
    flex-direction: column;
    row-gap: ${calculateRemSize(2)};
`;
