import {css} from "@emotion/react";

export type ElevationDepth = 0 | 1 | 2 | 3 | 4 | "deepShadow";

const rgbOpacity = [0, 0.3, 0.25, 0.2, 0.6];
const shadowBlur = [0, 10, 20, 40, 60];
const shadowOffsetY = [0, 4, 8, 16, 16];

export const elevation = (depth: ElevationDepth = 1, isText = false) => {
    const shadowType = `${isText ? "text" : "box"}-shadow`;
    return depth === "deepShadow"
        ? css`
              ${shadowType}: 0 4px 4px rgba(0, 0, 0, 0.25);
          `
        : css`
              ${shadowType}: 0 ${shadowOffsetY[depth]}px ${shadowBlur[depth]}px rgba(174, 177, 180, ${rgbOpacity[depth]});
          `;
};
