import {css} from "@emotion/react";

import {IModalProps} from "../../../types/IModalProps";

export const modalAlignStyle = (align: IModalProps["align"]) => {
    switch (align) {
        case "center":
            return css`
                justify-content: center;
            `;
        case "left":
            return css`
                justify-content: flex-start;
            `;

        case "right":
            return css`
                justify-content: flex-end;
            `;

        default:
            return "";
    }
};
