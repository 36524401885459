import React from "react";
import {Theme} from "@emotion/react";
import styled from "@emotion/styled";

import {IIcon} from "../interfaces/IIcon";
import {SvgIconWrapper} from "./SvgIconWrapper";

export const SvgIcon: React.FC<IIcon> = (props) => {
    const {fill, size = "1", wrapperColor, wrapperSize, wrapperType, children, onClick} = props;
    const hasWrapper = wrapperSize && wrapperColor;

    const icon = (
        <SvgIconBase
            width={10}
            height={10}
            onClick={!hasWrapper ? onClick : undefined}
            className={!hasWrapper ? props.className : undefined}
            fill={fill}
            size={size}
            viewBox="0 0 10 10"
        >
            {children}
        </SvgIconBase>
    );

    return wrapperSize && wrapperColor ? (
        <SvgIconWrapper onClick={onClick} className={props.className} wrapperColor={wrapperColor} wrapperSize={wrapperSize} wrapperType={wrapperType}>
            {icon}
        </SvgIconWrapper>
    ) : (
        icon
    );
};

const SvgIconBase = styled.svg<{fill?: string; theme?: Theme; size: string}>`
    fill: ${(props) => props.fill || props.theme.colors.secondary};
    transform: ${(props) => `scale(${props.size})`};
`;
