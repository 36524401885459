import React from "react";
import {css, SerializedStyles} from "@emotion/react";
import styled from "@emotion/styled";

import {calculateRemSize} from "@pg-design/helpers-css";
import {ExpandIcon} from "@pg-design/icons";
import {TrashIcon} from "@pg-design/icons";
import {Image} from "@pg-design/image";
import {Placeholder} from "@pg-design/image";

export interface IImagePreviewProps {
    width: string;
    height: string;
    alt: string;
    imgSrc: string | undefined;
    className?: string;
    aspectRatio?: number;
    imageHolderCss?: SerializedStyles;
    imageCss?: SerializedStyles;
    /*
        Provide below callbacks to enable icons for each functionality
     */
    removeImage?: () => void | null;
    cropImage?: (previewImage: string) => void;
}

export function ImageFieldPreview(props: IImagePreviewProps) {
    const {width, height, aspectRatio, alt, imgSrc, className, removeImage, cropImage, imageCss, imageHolderCss} = props;

    return (
        <ImageWrap aspectRatio={aspectRatio} className={className}>
            {imgSrc ? (
                <Image alt={alt} width={width} height={height} src={imgSrc} css={[imageHolder, imageHolderCss]} imageCss={[imageStyle, imageCss]} />
            ) : (
                <Placeholder />
            )}
            <div css={iconsWrap}>
                {cropImage && imgSrc && (
                    <ExpandIcon
                        onClick={() => {
                            cropImage(imgSrc);
                        }}
                        css={icon}
                        size="2.5"
                        wrapperSize="4.4"
                        wrapperColor="#fff"
                    />
                )}
                {removeImage && <TrashIcon onClick={removeImage} css={icon} size="3.2" wrapperSize="4.4" wrapperColor="#fff" />}
            </div>
        </ImageWrap>
    );
}

const ImageWrap = styled.div<{aspectRatio?: number}>`
    ${({aspectRatio}) => css`
        display: flex;
        position: relative;
        ${aspectRatio &&
        css`
            aspect-ratio: ${aspectRatio};
        `}
        overflow: hidden;
    `}
`;

const iconsWrap = css`
    position: absolute;
    z-index: 10;
    top: ${calculateRemSize(2)};
    right: ${calculateRemSize(2)};
    display: flex;
    column-gap: ${calculateRemSize(2)};
`;

const icon = css`
    cursor: pointer;
`;

const imageHolder = css`
    flex: 1 1 auto;
`;

const imageStyle = css`
    width: 100%;
`;
