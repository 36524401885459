import {useSelector} from "react-redux";
import {useTheme} from "@emotion/react";
import styled from "@emotion/styled";

import {Image} from "@pg-design/image-module";
import {useUserDevice} from "@pg-mono/user-device";

import {TdaDesktopWrap} from "../../smart_ad_server/components/TdaDesktopWrap";
import {tdaInternalBanners} from "../../smart_ad_server/constants/tda_internal_banners";
import {selectSsrTdaShow} from "../../smart_ad_server/reducer/smart_ads_reducer";
import {gtmHeroBanerClick} from "../../tracking/google_tag_manager/gtm_hero_banner";

export const TdaServerSide = () => {
    const tdaInternalBanner = tdaInternalBanners[0];
    const {isMobile} = useUserDevice();
    const showBanner = useSelector(selectSsrTdaShow());
    const theme = useTheme();

    if (isMobile || !showBanner) {
        return null;
    }

    const onTdaBannerClick = () => {
        gtmHeroBanerClick();

        window.open(tdaInternalBanner?.link, "_blank");
    };

    return (
        <TdaServerSideWrapper>
            <TdaDesktopWrap backgroundColor={theme.colors.gray[100]}>
                <TdaBanner
                    onClick={onTdaBannerClick}
                    src={tdaInternalBanner?.imgSet[0]}
                    src2x={tdaInternalBanner?.imgSet[1]}
                    src3x={tdaInternalBanner?.imgSet[2]}
                    alt="tda banner"
                    width="1100"
                    height="220"
                />
            </TdaDesktopWrap>
        </TdaServerSideWrapper>
    );
};

const TdaServerSideWrapper = styled.div`
    height: 220px;
    width: 100%;
`;

const TdaBanner = styled(Image)`
    width: 1100px;
    height: 220px;
    cursor: pointer;
`;
