import React, {ElementType, ReactNode, useContext} from "react";
import {css, Theme, useTheme} from "@emotion/react";

import {backgroundColor, p} from "@pg-design/helpers-css";
import {Text} from "@pg-design/text";

import {IModalProps} from "../../types/IModalProps";
import {ModalContext} from "../ModalContext";
import {ModalCloseButton} from "./ModalCloseButton";

interface IModalHeaderProps {
    as?: ElementType;
    variant?: "clean" | "bar" | "bar_mini";
    children?: ReactNode;
    showCloseButton?: boolean;
    className?: string;
}

const getTextVariant = (headerVariant: IModalHeaderProps["variant"]) => {
    switch (headerVariant) {
        case "clean":
            return "headline_3";
        case "bar":
            return "headline_6";
        default:
            return "body_copy_2";
    }
};

const getVariantColors = (headerVariant: IModalHeaderProps["variant"], modalTheme: IModalProps["modalTheme"]) => (theme: Theme) => {
    switch (headerVariant) {
        case "bar":
        case "bar_mini":
            if (modalTheme === "light") {
                return {
                    backgroundColor: theme.colors.gray[100],
                    color: theme.colors.secondary,
                    closeButtonBackgroundColor: theme.colors.gray[200],
                    closeButtonColor: theme.colors.secondary
                };
            } else {
                return {
                    backgroundColor: theme.colors.secondary,
                    color: "#fff",
                    closeButtonBackgroundColor: "transparent",
                    closeButtonColor: "#fff"
                };
            }
        default:
            if (modalTheme === "light") {
                return {
                    backgroundColor: "#fff",
                    color: theme.colors.secondary,
                    closeButtonBackgroundColor: "transparent",
                    closeButtonColor: theme.colors.secondary
                };
            } else {
                return {
                    backgroundColor: theme.colors.secondary,
                    color: "#fff",
                    closeButtonBackgroundColor: "transparent",
                    closeButtonColor: "#fff"
                };
            }
    }
};

const getIconSize = (headerVariant: IModalHeaderProps["variant"]) => {
    switch (headerVariant) {
        case "bar_mini":
            return "1.4";
        default:
            return "2.4";
    }
};

export const ModalHeader = (props: IModalHeaderProps) => {
    const {variant = "clean", showCloseButton = true, children} = props;
    const {modalTheme} = useContext(ModalContext);
    const theme = useTheme();

    const variantColors = getVariantColors(variant, modalTheme)(theme);
    const iconSize = getIconSize(variant);

    return (
        <div css={[modalHeaderWrapperStyle, backgroundColor(variantColors.backgroundColor)]} className={props.className}>
            <Text as={props.as ?? "h3"} variant={getTextVariant(variant)} color={variantColors.color} css={headerStyle}>
                {children}
            </Text>
            {showCloseButton && (
                <ModalCloseButton size={iconSize} wrapperColor={variantColors.closeButtonBackgroundColor} fillColor={variantColors.closeButtonColor} />
            )}
        </div>
    );
};

const modalHeaderWrapperStyle = css`
    display: flex;
    flex: 0 0 auto;
    align-items: center;
    ${p(0, 0, 0, 2)};
`;

const headerStyle = css`
    flex: 1;
`;
