import {IModalProps} from "../types/IModalProps";

export let defaultModalParams: IModalProps & {defaultRootSelector: string} = {
    defaultRootSelector: "#root",
    borderRadius: {mobile: 0, desktop: 2},
    fullScreen: "xs",
    modalTheme: "light",
    vAlign: "center",
    align: "center",
    zIndex: 9000
};

export const setModalDefaults = (params: Partial<typeof defaultModalParams>) => {
    defaultModalParams = {
        ...defaultModalParams,
        ...params
    };
};
