import {INavLeftElement} from "@pg-design/navigation";
import {appendQueryString} from "@pg-mono/request";
import {rpAppLink} from "@pg-mono/rp-routes";

import {PRICES_CITIES} from "../../report/price/constants/prices_cities";
import {gtmEventNavigationClick} from "../../tracking/google_tag_manager/gtm_event_navigation";
import {academyRealEstateLink, bigDataLink, competitionMonitoringLink, conferencesLink, developerAndMarketingLink, misteriousCustomerLink} from "./links";
import {NAVIGATION_REGIONS} from "./navigation_regions";

const NAV_SECOND_LEVEL_FLATS: INavLeftElement[] = NAVIGATION_REGIONS.map((region) => {
    const title = region.name;
    const url = rpAppLink.offer.listFriendly({friendlySlug: `nowe-mieszkania-${region.suffix}`});

    return {
        title,
        url,
        onClick: () => gtmEventNavigationClick("3", title),
        subElements: region.cities_flats.map((city) => {
            const cityTitle = `Mieszkania ${city.name}`;
            const cityUrl = rpAppLink.offer.listFriendly({friendlySlug: `nowe-mieszkania-${city.suffix}`});
            return {title: cityTitle, url: cityUrl, onClick: () => gtmEventNavigationClick("4", cityTitle)};
        })
    };
});

const NAV_SECOND_LEVEL_HOUSES: INavLeftElement[] = NAVIGATION_REGIONS.map((region) => {
    const title = `Domy ${region.name}`;
    const url = rpAppLink.offer.listFriendly({friendlySlug: `nowe-domy-${region.suffix}`});
    return {
        title,
        url,
        onClick: () => gtmEventNavigationClick("3", title),
        subElements: region.cities_houses.map((city) => {
            const cityTitle = `Domy ${city.name}`;
            const cityUrl = rpAppLink.offer.listFriendly({friendlySlug: `nowe-domy-${city.suffix}`});
            return {title: cityTitle, url: cityUrl, onClick: () => gtmEventNavigationClick("4", cityTitle)};
        })
    };
});

const NAV_SECOND_LEVEL_COMMERCIAL: INavLeftElement[] = [
    {name: "Lokale użytkowe Warszawa", suffix: "warszawa"},
    {name: "Lokale użytkowe Łódź", suffix: "lodz"},
    {name: "Lokale użytkowe Wrocław", suffix: "wroclaw"},
    {name: "Lokale użytkowe Poznań", suffix: "poznan"},
    {name: "Lokale użytkowe Kraków", suffix: "krakow"},
    {name: "Lokale użytkowe Gdańsk", suffix: "gdansk"},
    {name: "Lokale użytkowe Katowice", suffix: "katowice"}
].map((city) => {
    const title = city.name;
    const url = rpAppLink.offer.listFriendly({friendlySlug: `nowe-lokale-uzytkowe-${city.suffix}`});
    return {title, url, onClick: () => gtmEventNavigationClick("3", title)};
});

const NAV_SECOND_LEVEL_DEVELOPERS: INavLeftElement[] = [
    {name: "Deweloperzy Warszawa", suffix: "warszawa"},
    {name: "Deweloperzy Kraków", suffix: "krakow"},
    {name: "Deweloperzy Białystok", suffix: "bialystok"},
    {name: "Deweloperzy Wrocław", suffix: "wroclaw"},
    {name: "Deweloperzy Poznań", suffix: "poznan"},
    {name: "Deweloperzy Gdańsk", suffix: "gdansk"},
    {name: "Deweloperzy Lublin", suffix: "lublin"},
    {name: "Deweloperzy Toruń", suffix: "torun"},
    {name: "Deweloperzy Rzeszów", suffix: "rzeszow"},
    {name: "Deweloperzy Szczecin", suffix: "szczecin"},
    {name: "Deweloperzy Łódź", suffix: "lodz"}
].map((city) => {
    const title = city.name;
    const url = rpAppLink.vendor.listFriendly({friendlySlug: city.suffix});
    return {title, url, onClick: () => gtmEventNavigationClick("3", title)};
});

const NAV_SECOND_LEVEL_LUXURIOUS_FLATS: INavLeftElement[] = [
    {name: "Apartamenty Warszawa", suffix: "warszawa"},
    {name: "Apartamenty Kraków", suffix: "krakow"},
    {name: "Apartamenty Wrocław", suffix: "wroclaw"},
    {name: "Apartamenty Poznań", suffix: "poznan"},
    {name: "Apartamenty Łódź", suffix: "lodz"},
    {name: "Apartamenty Bydgoszcz", suffix: "bydgoszcz"}
].map((city) => {
    const title = city.name;
    const url = rpAppLink.offer.listFriendly({friendlySlug: `luksusowe-mieszkania-${city.suffix}`});
    return {title, url, onClick: () => gtmEventNavigationClick("3", title)};
});

const NAV_THIRD_LEVEL_INVEST_FLATS: INavLeftElement[] = [
    {name: "Apartamenty inwestycyjne Warszawa", suffix: "warszawa"},
    {name: "Apartamenty inwestycyjne Gdańsk", suffix: "gdansk"},
    {name: "Apartamenty inwestycyjne Kraków", suffix: "krakow"},
    {name: "Apartamenty inwestycyjne Łódź", suffix: "lodz"},
    {name: "Apartamenty inwestycyjne Poznań", suffix: "poznan"},
    {name: "Apartamenty inwestycyjne Wrocław", suffix: "wroclaw"}
].map((category) => {
    const title = category.name;
    const url = rpAppLink.investmentOffer.investmentCategory.subcategory.base({category: "apartamenty-inwestycyjne", subcategory: category.suffix});
    return {title, url, onClick: () => gtmEventNavigationClick("4", title)};
});

const NAV_THIRD_LEVEL_INVEST_HOLIDAY_FLATS: INavLeftElement[] = [
    {name: "Apartamenty wakacyjne nad morzem", suffix: "nad-morzem"},
    {name: "Apartamenty wakacyjne nad jeziorem", suffix: "nad-jeziorem"},
    {name: "Apartamenty wakacyjne w górach", suffix: "w-gorach"}
].map((category) => {
    const title = category.name;
    const url = rpAppLink.investmentOffer.investmentCategory.subcategory.base({category: "apartamenty-wakacyjne", subcategory: category.suffix});
    return {title, url, onClick: () => gtmEventNavigationClick("4", title)};
});

const NAV_SECOND_LEVEL_INVEST: INavLeftElement[] = [
    {name: "Condohotele", suffix: "condohotel"},
    {name: "Apartamenty inwestycyjne", suffix: "apartamenty-inwestycyjne", subElements: NAV_THIRD_LEVEL_INVEST_FLATS},
    {name: "Apartamenty wakacyjne", suffix: "apartamenty-wakacyjne", subElements: NAV_THIRD_LEVEL_INVEST_HOLIDAY_FLATS},
    {name: "Nieruchomości Hiszpania", suffix: "za-granica", subcategory: "hiszpania"},
    {name: "Zobacz wszystkie", url: rpAppLink.investmentOffer.landingPage(), hideOnDesktop: true}
].map((category) => {
    const title = category.name;
    const baseUrl = category.suffix && rpAppLink.investmentOffer.investmentCategory.base({category: category.suffix});
    const urlWithSubcategory = category.subcategory
        ? rpAppLink.investmentOffer.investmentCategory.subcategory.base({category: category.suffix, subcategory: category.subcategory})
        : null;
    const url = category.url || urlWithSubcategory || baseUrl;
    return {title, url, onClick: () => gtmEventNavigationClick("3", title), subElements: category?.subElements, hideOnDesktop: category?.hideOnDesktop};
});

const NAV_FIRST_LEVEL = [
    {
        title: "Nowe mieszkania",
        url: rpAppLink.offer.listFriendly({friendlySlug: "nowe-mieszkania"}),
        onClick: () => gtmEventNavigationClick("2", "Nowe mieszkania"),
        subElements: NAV_SECOND_LEVEL_FLATS
    },
    {
        title: "Nowe domy",
        url: rpAppLink.offer.listFriendly({friendlySlug: "nowe-domy"}),
        onClick: () => gtmEventNavigationClick("2", "Nowe domy"),
        subElements: NAV_SECOND_LEVEL_HOUSES
    },
    {
        title: "Nowe lokale użytkowe",
        url: rpAppLink.offer.listFriendly({friendlySlug: "nowe-lokale-uzytkowe"}),
        onClick: () => gtmEventNavigationClick("2", "Nowe lokale użytkowe"),
        subElements: NAV_SECOND_LEVEL_COMMERCIAL
    },
    {
        title: "Deweloperzy",
        url: rpAppLink.vendor.list(),
        onClick: () => gtmEventNavigationClick("2", "Deweloperzy"),
        subElements: NAV_SECOND_LEVEL_DEVELOPERS
    },
    {
        title: "Luksusowe apartamenty",
        url: rpAppLink.offer.listFriendly({friendlySlug: "luksusowe-mieszkania"}),
        onClick: () => gtmEventNavigationClick("2", "Luksusowe apartamenty"),
        subElements: NAV_SECOND_LEVEL_LUXURIOUS_FLATS
    },
    {
        title: "Inwestowanie w nieruchomości",
        url: rpAppLink.investmentOffer.landingPage(),
        onClick: () => gtmEventNavigationClick("2", "Inwestowanie w nieruchomości"),
        subElements: NAV_SECOND_LEVEL_INVEST
    },
    {
        title: "Ranking inwestycji",
        url: rpAppLink.ranking.base(),
        onClick: () => gtmEventNavigationClick("2", "Ranking inwestycji")
    },
    {
        title: "Konfigurator mieszkania",
        url: rpAppLink.propertyConfigurator(),
        onClick: () => gtmEventNavigationClick("2", "Konfigurator mieszkania")
    }
];

export const NAV_SECOND_LEVEL_FOR_DEVELOPERS: INavLeftElement[] = [
    {
        title: "Sprzedawaj więcej!",
        url: rpAppLink.forDevelopers.base(),
        onClick: () => gtmEventNavigationClick("2", "Sprzedawaj więcej!")
    },
    {
        title: "Akademia Real Estate",
        url: academyRealEstateLink,
        onClick: () => gtmEventNavigationClick("2", "Akademia Real Estate"),
        target: "_blank"
    },
    {
        title: "Deweloper & Marketing",
        url: developerAndMarketingLink,
        onClick: () => gtmEventNavigationClick("2", "Deweloper & Marketing"),
        target: "_blank"
    },
    {
        title: "Monitoring konkurencji",
        url: competitionMonitoringLink,
        onClick: () => gtmEventNavigationClick("2", "Monitoring konkurencji"),
        target: "_blank"
    },
    {
        title: "Tajemniczy klient",
        url: misteriousCustomerLink,
        onClick: () => gtmEventNavigationClick("2", "Tajemniczy klient"),
        target: "_blank"
    },
    {
        title: "Big Data",
        url: appendQueryString(bigDataLink, {
            utm_campaign: "RP_Linkowanie",
            utm_medium: "menu",
            utm_source: "rynekpierwotny"
        }),
        onClick: () => gtmEventNavigationClick("2", "Big Data"),
        target: "_blank"
    },
    {
        title: "Konferencje",
        url: conferencesLink,
        onClick: () => gtmEventNavigationClick("2", "Konferencje"),
        target: "_blank"
    }
];

export const NAV_SECOND_LEVEL_FLAT_PRICES: INavLeftElement[] = [
    ...PRICES_CITIES.map((city) => {
        return {
            title: `Ceny mieszkań ${city.name}`,
            url: rpAppLink.prices.flats.city({regionSlug: city.slug}),
            onClick: () => gtmEventNavigationClick("2", `Ceny mieszkań ${city.name}`)
        };
    })
];

export const NAV_MAIN_ELEMENTS = [
    {
        title: "Nieruchomości",
        url: rpAppLink.offer.listFriendly({friendlySlug: "nowe-mieszkania-i-domy"}),
        onClick: () => gtmEventNavigationClick("1", "Nieruchomości"),
        subElements: NAV_FIRST_LEVEL
    },
    {
        title: "Wykończenie wnętrz",
        url: rpAppLink.architect.list(),
        onClick: () => gtmEventNavigationClick("1", "Wykończenie wnętrz")
    },
    {
        title: "Kredyty",
        url: rpAppLink.credits.base(),
        onClick: () => gtmEventNavigationClick("1", "Kredyty")
    },
    {
        title: "Strefa wiedzy",
        url: rpAppLink.article.list(),
        onClick: () => gtmEventNavigationClick("1", "Strefa wiedzy")
    },
    {
        title: "Dla dewelopera",
        url: rpAppLink.forDevelopers.base(),
        onClick: () => gtmEventNavigationClick("1", "Dla dewelopera"),
        subElements: NAV_SECOND_LEVEL_FOR_DEVELOPERS
    },
    {
        title: "Ceny mieszkań",
        url: rpAppLink.prices.flats.base(),
        onClick: () => gtmEventNavigationClick("1", "Ceny mieszkań"),
        subElements: NAV_SECOND_LEVEL_FLAT_PRICES
    }
];
