import type {FocusEvent} from "react";
import {useRef, useState} from "react";

export const useBlurOutside = <T extends HTMLElement>() => {
    const ref = useRef<T>(null);

    const [isFocused, setIsFocused] = useState(false);

    const onBlur = (event: FocusEvent) => {
        if (!ref.current || !event.relatedTarget || !ref.current.contains(event.relatedTarget)) {
            setIsFocused(false);
        }
    };

    const onFocus = (_event: FocusEvent) => {
        setIsFocused(true);
    };

    return {
        wrapperProps: {
            ref,
            onBlur,
            tabIndex: -1
        },
        targetElementProps: {
            onBlur,
            onFocus
        },
        isFocused,
        setIsFocused
    };
};
