import {configureStore} from "@reduxjs/toolkit";

import {promiseMiddleware} from "@pg-mono/request";
import {enableErrorResponseState} from "@pg-mono/response-state";

import {isDevelopment, isServer} from "../app/read_rp_environment_variables";
import {createRtkqMiddleware} from "../app/utils/createRtkqMiddleware";
import {notifyBugsnag} from "../errors/bugsnag/notify_bugsnag";
import {getBugsnagErrorBody} from "../errors/utils/get_bugsnag_error_body";
import {getErrorGroupHash} from "../errors/utils/get_error_group_hash";
import {IRPServicesMeta} from "./types/IRPServicesMeta";
import {apiMiddlewares, rootReducer} from "./root_reducer";

interface ICreateStoreOptions {
    ssrUtils: IRPServicesMeta | null;
    preloadedState?: IRootState;
    onErrorAlert?: (dispatch: IAppDispatch) => void;
}

export function createRpStore(options: ICreateStoreOptions) {
    const promiseMiddlewareLegacy = promiseMiddleware({
        createErrorStatusAction: (status) => enableErrorResponseState(status),
        notifyError: (error, context) => {
            if (isServer && options.ssrUtils) {
                const errorBody = getBugsnagErrorBody(error);
                const {currentRoute, requestHeaders, requestCookies} = options.ssrUtils as IRPServicesMeta;

                notifyBugsnag({...error, ...errorBody}, context, {
                    currentUrl: currentRoute.url,
                    currentRoute,
                    originalError: error,
                    cookies: requestCookies,
                    headers: requestHeaders
                });
            } else {
                notifyBugsnag(error, context);
            }
        },
        logData: options.ssrUtils ? {url: options.ssrUtils.currentRoute.url} : {}
    });

    const rtkqMiddleware = createRtkqMiddleware({
        createErrorStatusAction: (status) => enableErrorResponseState(status),
        notifyError: (error, context) => {
            if (isServer && options.ssrUtils) {
                const errorGroupingHash = getErrorGroupHash(`${error.rtkEndpointName}`);

                const errorBody = {
                    name: `${error.name}: ${error.rtkEndpointName}`,
                    message: error.message,
                    ...(errorGroupingHash ? {groupingHash: errorGroupingHash} : {})
                };

                const {currentRoute, requestHeaders, requestCookies} = options.ssrUtils as IRPServicesMeta;

                notifyBugsnag({...error, ...errorBody}, context, {
                    currentUrl: currentRoute.url,
                    currentRoute,
                    originalError: error,
                    cookies: requestCookies,
                    headers: requestHeaders
                });
            } else {
                notifyBugsnag(error, context);
            }
        },
        ssrUtils: options.ssrUtils
    });

    const middlewares = [rtkqMiddleware, ...apiMiddlewares];

    const defaultMiddlewareConfig = {
        thunk: {
            extraArgument: {
                ssrUtils: options.ssrUtils
            }
        },
        /**
         * TODO: We should rethink our approach to notifications and errors, currently their values might be non serializable
         *     https://redux-toolkit.js.org/usage/usage-guide#working-with-non-serializable-data
         */
        serializableCheck: false,
        immutableCheck: {
            // TODO: remove this warnAfter value after me migrate all apis to use redux-toolkit
            warnAfter: 2000
        }
    };

    return configureStore({
        // @ts-ignore
        preloadedState: options?.preloadedState || undefined,
        reducer: rootReducer,
        // @ts-ignore
        middleware: (getDefaultMiddleware) => getDefaultMiddleware(defaultMiddlewareConfig).prepend(promiseMiddlewareLegacy).concat(middlewares),
        devTools: isDevelopment
    });
}

type IRootReducer = typeof rootReducer;
//  TODO: After moving all reducers to redux-toolkit, remember, to migrate RpStore to IRpStore
export type RpStore = ReturnType<typeof createRpStore>;
export type IRootState = ReturnType<IRootReducer>;
export type IAppDispatch = RpStore["dispatch"];
