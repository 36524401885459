import {css} from "@emotion/react";
import styled from "@emotion/styled";

import {IFieldState} from "../types";

export const TextInputBase = styled.div<{fieldState: IFieldState}>`
    ${({fieldState}) => css`
        cursor: ${fieldState === "disabled" ? "inherit" : "text"};
    `}
`;
