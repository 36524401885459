import {useSelector} from "react-redux";
import {css, Theme} from "@emotion/react";

import {WideContent} from "@pg-design/grid-module";
import {calculateRemSize, flex, mb, pv} from "@pg-design/helpers-css";
import {Image} from "@pg-design/image-module";
import {Text} from "@pg-design/text-module";

import {ViewType} from "../../view_type/ViewType";
import {IRPStore} from "../rp_reducer";

const forbes2021 = require("../images/awards/forbes_2021.png");
const forbes2022 = require("../images/awards/forbes_2022.png");
const digitalExcellence2022 = require("../images/awards/digital_excellence_2022.png");
const laurKlienta2023 = require("../images/awards/laur_klienta_2023.png");
const dobryPracodawca2023 = require("../images/awards/dobry_pracodawca_2023.png");
const gazeleBiznesu2023 = require("../images/awards/gazele_biznesu_2023.png");

const awards = [
    {alt: "Nagroda Forbes 2022", src: forbes2022, height: "90", width: "117"},
    {alt: "Nagroda Forbes 2021", src: forbes2021, height: "90", width: "117"},
    {alt: "Nagroda Digital Excellence Awards 2022", src: digitalExcellence2022, height: "90", width: "137"},
    {alt: "Nagroda Laur Klienta 2023", src: laurKlienta2023, height: "90", width: "90"},
    {alt: "Nagroda Dobry Pracodawca 2023", src: dobryPracodawca2023, height: "90", width: "90"},
    {alt: "Nagroda Gazele Biznesu 2023", src: gazeleBiznesu2023, height: "83", width: "212"}
];

export const Awards = () => {
    const viewType = useSelector((state: IRPStore) => state.viewType.current);

    if (viewType && [ViewType.OFFER_LIST_MAP, ViewType.FOR_INVESTMENT_LIST_MAP].includes(viewType)) {
        return null;
    }

    return (
        <div css={wrapper}>
            <WideContent>
                <Text variant="headline_6" as="p" css={mb(3)}>
                    Nagrody i wyróżnienia
                </Text>

                <div css={imagesWrapper}>
                    {awards.map((award) => {
                        return <Image key={award.alt} {...award} loading="lazy" />;
                    })}
                </div>
            </WideContent>
        </div>
    );
};

const wrapper = css`
    background-color: #fff;
    ${pv(4)};
    overflow: hidden;
`;

const imagesWrapper = (theme: Theme) => css`
    ${flex("center")};
    gap: ${calculateRemSize(4)};
    flex-wrap: wrap;
    width: 355px;

    @media (min-width: ${theme.breakpoints.xs}) {
        min-width: 474px;
        width: 100%;
    }

    img {
        object-fit: contain;
        max-height: 90px;
    }
`;
