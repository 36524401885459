import React, {useState} from "react";
import {connect} from "react-redux";
import {css, Theme} from "@emotion/react";
import {bindActionCreators, Dispatch} from "redux";

import {backgroundColor, borderRadius, mh, ml, mv, p} from "@pg-design/helpers-css";
import {SearchIcon} from "@pg-design/icons-module";
import {Pill} from "@pg-design/pill";
import {FormActions} from "@pg-mono/form";
import {head} from "@pg-mono/nodash";

import {IRPStore} from "../../../../app/rp_reducer";
import {MobileOptionsModal} from "../../../../atoms/MobileOptionsModal";
import {SearchTab} from "../../../../search/actions/fetch_search_all_action";
import {searchFormActions} from "../../../../search/actions/search_form_actions";
import {resetActiveDropdownItem} from "../../../../search/actions/set_active_dropdown_item";
import {DesktopBlend} from "../../../../search/components/atoms/atoms";
import {ISearchInputValue} from "../../../../search/components/ISearchInputValue";
import {SearchAutocomplete} from "../../../../search/components/search_autocomplete/SearchAutocomplete";
import {StyledInputWrapper} from "../../../../search/components/search_autocomplete/SearchAutocompleteInput";
import {searchFormFields} from "../../../../search/constants/search_form_constants";
import {IPromotionRegion, updatePromotionRegionList} from "../../../actions/fetch_promotion_list_region";
import {fetchPromotionSearchList} from "../../../actions/fetch_promotion_search_lists";
import {PromotionType} from "../../../helpers/PromotionType";
import {usePromotionListFilterChange} from "../../hooks/usePromotionListFilterChange";
import {PromotionListSearchFilterAutocompleteDropdown} from "./PromotionListSearchFilterAutocompleteDropdown";

/* eslint-disable @typescript-eslint/no-explicit-any */
export type IPromotionSearchFormValuesType = Record<"search", any>;

interface IStateProps {
    formValues: IPromotionSearchFormValuesType;
    latestQuery: Record<string, string | string[]>;
    promotionType: PromotionType;
}

interface IActionsProps {
    formActions: FormActions<Record<keyof typeof searchFormFields, unknown>>;
    resetActiveDropdownItem: typeof resetActiveDropdownItem;
    fetchPromotionSearchList: (searchInput: string) => Promise<SearchTab>;
    updatePromotionRegionList: (region: IPromotionRegion | null) => {type: string; result: IPromotionRegion | null};
}

interface IPromotionListSearchFilter extends IStateProps, IActionsProps {}

const PromotionListSearchFilterC = (props: IPromotionListSearchFilter) => {
    const [isMobileSearchActive, setMobileSearchActive] = useState(false);
    const [isDesktopSearchBlendActive, setDesktopSearchBlendActive] = useState(false);

    const onPromotionListFilterChange = usePromotionListFilterChange();

    const onSubmit = async (formValues: IPromotionSearchFormValuesType) => {
        const searchValue = formValues["search"] as ISearchInputValue;

        if (searchValue.tabType === SearchTab.Regions) {
            onPromotionListFilterChange(props.promotionType || undefined, searchValue.regions?.[0]?.slug);
        }
    };

    const onAfterChange = (name?: string, value?: ISearchInputValue | number | undefined) => {
        const currentValues = name ? {...props.formValues, [name]: value} : props.formValues;

        //  submit only if user selected new region
        if (props.latestQuery.region === currentValues.search.regions?.[0]?.id) {
            return;
        }

        props.updatePromotionRegionList(head(currentValues.search?.regions) || null);

        return onSubmit(currentValues);
    };

    const onSearchSubmit = () => onSubmit(props.formValues);

    const selectedRegion = props.formValues?.search?.regions?.[0];
    const placeholder = "Miasto lub region";

    const renderSearchAutocomplete = (onDropdownStatusChange: React.Dispatch<React.SetStateAction<boolean>>, isInModal?: boolean) => {
        return (
            <div css={searchAutocompleteWrapper}>
                <SearchAutocomplete
                    values={props.formValues}
                    onChange={props.formActions.update}
                    onAfterChange={onAfterChange}
                    onSubmit={onSearchSubmit}
                    onDropdownStatusChange={onDropdownStatusChange}
                    onAfterTabSwitch={props.resetActiveDropdownItem}
                    placeholder={placeholder}
                    disableMultiRegion
                    fetchAllSearchLists={props.fetchPromotionSearchList}
                    isInModal={isInModal}
                    autoFocus
                    hideArrowIcon
                    renderDropdownList={(props) => (
                        <PromotionListSearchFilterAutocompleteDropdown
                            dropdownIsOpen={props.dropdownIsOpen}
                            search={props.search}
                            selectedTab={props.selectedTab}
                            onLinkClick={props.onLinkClick}
                            requestStateObj={props.requestStateObj}
                            activeItem={props.activeItem}
                            offers={[]}
                            places={[]}
                            regions={props.regions}
                            removeRegionTag={props.removeRegionTag}
                            vendors={[]}
                        />
                    )}
                />
            </div>
        );
    };

    return (
        <div css={[backgroundColor("#fff")]}>
            <div onClick={() => setMobileSearchActive(true)} css={[mobileInputTrigger]}>
                <div css={iconWrapper}>
                    <SearchIcon size="2" css={inputIconSearchIcon} />
                </div>

                {selectedRegion ? (
                    <Pill size="small" css={[ml(1.5)]}>
                        {selectedRegion.name}
                    </Pill>
                ) : (
                    placeholder
                )}
            </div>

            <MobileOptionsModal isOpen={isMobileSearchActive} modalHeading="Wyszukiwanie" onModalClose={() => setMobileSearchActive(false)}>
                {renderSearchAutocomplete(setMobileSearchActive, true)}
            </MobileOptionsModal>

            <DesktopBlend isActive={isDesktopSearchBlendActive} isFullScreen={false} top="15rem" heightDecrease="(13rem + 60px)" />

            <div css={[searchDesktopWrapper]}>{renderSearchAutocomplete(setDesktopSearchBlendActive)}</div>
        </div>
    );
};

export const PromotionListSearchFilter = connect(mapStateToProps, mapActionsToProps)(PromotionListSearchFilterC);

function mapStateToProps(state: IRPStore): IStateProps {
    return {
        formValues: state.search.formValues,
        latestQuery: state.promotionList.latestQuery,
        promotionType: state.promotionList.form.formValues.type
    };
}

function mapActionsToProps(dispatch: Dispatch): IActionsProps {
    return {
        formActions: bindActionCreators({...searchFormActions}, dispatch),
        ...bindActionCreators(
            {
                resetActiveDropdownItem,
                fetchPromotionSearchList,
                updatePromotionRegionList
            },
            dispatch
        )
    };
}

const searchDesktopWrapper = (theme: Theme) => css`
    position: relative;
    display: none;

    ${StyledInputWrapper} {
        ${borderRadius()};
    }

    @media (min-width: ${theme.breakpoints.md}) {
        ${mh("auto")};
        ${mv(0)};
        display: flex;
        align-items: center;
        flex-grow: 1;
        flex-shrink: 1;
        flex-wrap: wrap;
    }

    @media (min-width: ${theme.breakpoints.lg}) {
        flex-wrap: unset;
        overflow-x: visible;
    }
`;

const mobileInputTrigger = (theme: Theme) => css`
    position: relative;
    display: flex;
    align-items: center;
    cursor: text;
    flex-basis: 100%;
    width: 100%;
    font-size: 1.4rem;
    height: 5rem;
    ${borderRadius()};
    border: 1px solid ${theme.colors.gray[600]};
    color: ${theme.colors.secondary};
    ${backgroundColor("#fff")};
    ${p(0, 2, 0, 5)};

    @media (min-width: ${theme.breakpoints.md}) {
        display: none;
    }
`;

const iconWrapper = css`
    position: absolute;
    top: 1.25rem;
    left: 2.2rem;
    cursor: text;
    z-index: 1;
`;

const searchAutocompleteWrapper = (theme: Theme) => css`
    position: relative;
    flex-grow: 1;
    flex-shrink: 1;
    height: calc(100% - 6rem);
    display: flex;
    flex-direction: column;

    @media (min-width: ${theme.breakpoints.sm}) {
        padding: 0;
    }

    @media (min-width: ${theme.breakpoints.md}) {
        max-height: 100%;
        width: 100%;
    }

    @media (min-width: ${theme.breakpoints.lg}) {
        width: calc(50% - 2rem);
    }
`;

const inputIconSearchIcon = (theme: Theme) => css`
    fill: ${theme.colors.secondary};
`;
