import React, {useContext} from "react";
import {css, useTheme} from "@emotion/react";

import {backgroundColor, color, onDesktop, p} from "@pg-design/helpers-css";

import {ModalContext} from "../ModalContext";

interface IModalContentProps {
    maxWidth?: string;
    maxWidthDesktop?: string;
    children?: React.ReactNode;
    className?: string;
}

const maxWidthStyle = (maxWidth?: string, maxWidthDesktop?: string) => css`
    ${maxWidth ? `max-width: ${maxWidth}` : ""};

    ${onDesktop(css`
        ${maxWidthDesktop ? `max-width: ${maxWidthDesktop}` : ""};
    `)};
`;

export const ModalContent = (props: IModalContentProps) => {
    const theme = useTheme();
    const {modalTheme} = useContext(ModalContext);

    const textColorValue = modalTheme === "light" ? theme.colors.secondary : "#fff";
    const backgroundColorValue = modalTheme === "light" ? "#fff" : theme.colors.secondary;

    return (
        <div
            css={[childrenHolder, color(textColorValue), backgroundColor(backgroundColorValue), maxWidthStyle(props.maxWidth, props.maxWidthDesktop)]}
            className={props.className}
        >
            {props.children}
        </div>
    );
};

const childrenHolder = css`
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1 1 100%;
    height: 100%;
    overflow: auto;
    ${p(2)};
`;
