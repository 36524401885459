import {css} from "@emotion/react";
import styled from "@emotion/styled";

import {pointer} from "@pg-design/helpers-css";

export const TdaDesktopWrap = styled.div<{backgroundColor: string}>`
    ${({backgroundColor}) => css`
        ${pointer};
        position: relative;
        height: 220px;
        width: 100%;
        display: flex;
        justify-content: center;
        background: ${backgroundColor};
    `}
`;
